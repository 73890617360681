import { Result } from 'interfaces/Result'
import { ResultWithValue } from 'interfaces/ResultWithValue'

export default class ResultUtility {
  /**
   * User-defined type guards to detect if the object is a Result
   * @param object the object to check
   * @returns returns true if the object is a Result, otherwise false
   */
  static isResult(object: unknown): object is Result {
    return Object.prototype.hasOwnProperty.call(object, 'status')
  }

  /**
   * User-defined type guards to detect if the object is a Result
   * @param object the object to check
   * @returns returns true if the object is a Result, otherwise false
   */
  static isResultWithValue<T>(object: unknown): object is ResultWithValue<T> {
    return (
      Object.prototype.hasOwnProperty.call(object, 'status') &&
      Object.prototype.hasOwnProperty.call(object, 'value')
    )
  }

  static handleResult(result: Result) {
    if (!result.status) {
      throw new Error(result.errorMessage)
    }

    return result.status
  }

  static handleResultWithValue<T>(result: ResultWithValue<T>) {
    if (!result.status) {
      throw new Error(result.errorMessage)
    }
    return result.value
  }
}
