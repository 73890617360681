import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import AdminSidebar from 'components/navigation-bars/admin/AdminSidebar'
import AdminBottomNavigationBar from 'components/navigation-bars/admin/AdminBottomNavigationBar'

const AdministrationLayout = () => {
  return (
    <>
      <div className="h-full">
        <AdminBottomNavigationBar />
        <AdminSidebar />
        <div className=" sm:ml-32 xl:ml-80 md:mr-16">
          <Outlet />
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  )
}

export default AdministrationLayout
