import Exception from "./Exception"

export default class UnauthorizedException extends Exception {
  constructor() {
    super("L'utente non è autorizzato")

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, UnauthorizedException.prototype)
  }
}
