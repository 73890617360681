import axios, { AxiosResponse } from 'axios'
import authService from 'api/services/AuthorizeService'
import UnauthorizedException from 'api/exceptions/UnauthorizedException'
import domainService from 'api/services/DomainService'
import ForbiddenException from 'api/exceptions/ForbiddenException'
import ResultUtility from 'utilities/ResultUtility'
import globalizationService from './GlobalizationService'

axios.defaults.withCredentials = true

export interface IRequestConfiguration {
  onUploadProgress?: (progressEvent: any) => void
  onDownloadProgress?: (progressEvent: any) => void
}

export class RequestService {
  async Get<R = any>(
    url: string,
    id?: string,
    anonymous?: boolean,
    configuration?: IRequestConfiguration,
    doNotRequireOrganization?: boolean
  ): Promise<R> {
    let headers: any
    if (!anonymous) {
      if (!(await authService.isAuthenticated())) {
        throw new UnauthorizedException()
      }

      if (!doNotRequireOrganization) {
        const organization = domainService.GetCurrentOrganization()
        if (organization == null) {
          throw new ForbiddenException()
        }

        headers = {
          Organization: `${organization.id}`,
          SelectedCulture: globalizationService.GetCurrentCulture()
        }
      }
    }

    console.debug(`DEBUG RequestService.Get ${url}${id ? `/${id}` : ''}`)

    const responseResult = await axios.get(`${url}${id ? `/${id}` : ''}`, {
      headers: {
        ...headers,
        SelectedCulture: globalizationService.GetCurrentCulture()
      },
      onUploadProgress: configuration?.onUploadProgress,
      onDownloadProgress: configuration?.onDownloadProgress
    })

    return this.handleResponse(responseResult)
  }

  async getContent<R = Blob | MediaSource>(
    url: string,
    anonymous?: boolean,
    configuration?: IRequestConfiguration
  ): Promise<R> {
    let headers: any
    if (!anonymous) {
      if (!(await authService.isAuthenticated())) {
        throw new UnauthorizedException()
      }

      const organization = domainService.GetCurrentOrganization()
      if (organization == null) {
        throw new ForbiddenException()
      }

      headers = {
        Organization: `${organization.id}`,
        SelectedCulture: globalizationService.GetCurrentCulture()
      }
    }

    console.debug(`DEBUG RequestService.GetContent ${url}`)

    const responseResult = await axios.get(url, {
      responseType: 'blob',
      headers: {
        ...headers,
        SelectedCulture: globalizationService.GetCurrentCulture()
      },
      onUploadProgress: configuration?.onUploadProgress,
      onDownloadProgress: configuration?.onDownloadProgress
    })

    return responseResult.data
  }

  async Delete<R = any>(
    url: string,
    id?: string,
    anonymous?: boolean
  ): Promise<R> {
    let headers: any
    if (!anonymous) {
      if (!(await authService.isAuthenticated())) {
        throw new UnauthorizedException()
      }

      const organization = domainService.GetCurrentOrganization()
      if (organization == null) {
        throw new ForbiddenException()
      }

      headers = {
        Organization: `${organization.id}`,
        SelectedCulture: globalizationService.GetCurrentCulture()
      }
    }

    console.debug(`DEBUG RequestService.Delete ${url}${id ? `/${id}` : ''}`)

    const responseResult = await axios.delete(`${url}${id ? `/${id}` : ''}`, {
      headers: {
        ...headers,
        SelectedCulture: globalizationService.GetCurrentCulture()
      }
    })

    return this.handleResponse(responseResult)
  }

  async Post<R = any>(
    url: string,
    data?: any,
    headerData?: any,
    anonymous?: boolean,
    configuration?: IRequestConfiguration
  ): Promise<R> {
    let headers: any | undefined
    if (!anonymous) {
      if (!(await authService.isAuthenticated())) {
        throw new UnauthorizedException()
      }

      const organization = domainService.GetCurrentOrganization()
      if (organization == null) {
        throw new ForbiddenException()
      }

      headers = {
        Organization: `${organization.id}`,
        SelectedCulture: globalizationService.GetCurrentCulture()
      }
    }

    const responseResult = await axios.post(`${url}`, data, {
      headers: {
        ...headers,
        ...headerData
      },
      onUploadProgress: configuration?.onUploadProgress,
      onDownloadProgress: configuration?.onDownloadProgress
    })

    return this.handleResponse(responseResult)
  }

  async Put<T = any>(
    url: string,
    data: any,
    headerData?: any,
    anonymous?: boolean,
    configuration?: IRequestConfiguration
  ): Promise<T> {
    let headers: any
    if (!anonymous) {
      if (!(await authService.isAuthenticated())) {
        throw new UnauthorizedException()
      }

      const organization = domainService.GetCurrentOrganization()
      if (organization == null) {
        throw new ForbiddenException()
      }

      headers = {
        Organization: `${organization.id}`,
        SelectedCulture: globalizationService.GetCurrentCulture()
      }
    }

    const responseResult = await axios.put(`${url}`, data, {
      headers: {
        ...headers,
        ...headerData
      },
      onUploadProgress: configuration?.onUploadProgress,
      onDownloadProgress: configuration?.onDownloadProgress
    })

    return this.handleResponse(responseResult)
  }

  handleResponse(response: AxiosResponse<any, any>) {
    const data = response.data

    if (ResultUtility.isResultWithValue(data)) {
      return ResultUtility.handleResultWithValue(data)
    }

    if (ResultUtility.isResult(data)) {
      return ResultUtility.handleResult(data)
    }

    return data
  }

  static instance() {
    return requestService
  }
}

const requestService = new RequestService()

export default requestService
