import localizationService from 'api/services/LocalizationService'
import globalizationService from 'api/services/GlobalizationService'
import ICountry from 'interfaces/localization/ICountry'
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  ReactElement
} from 'react'
import { useParams } from 'react-router-dom'
import PromiseUtility from 'utilities/PromiseUtility'

export interface IGlobalization {
  culture: string | undefined
  countries?: ICountry[]
}

// create contexts
const GlobalizationContext = createContext<IGlobalization | undefined>(
  undefined
)
const GlobalizationContextUpdater = createContext<
  IGlobalizationContextUpdater | undefined
>(undefined)

// context hook
const useGlobalizationContext = (): IGlobalization | undefined => {
  // get the context
  return useContext(GlobalizationContext)
}

interface IGlobalizationContextUpdater {
  updateCountries: () => Promise<void>
}

// context consumer hook
const useGlobalizationContextUpdater = ():
  | IGlobalizationContextUpdater
  | undefined => {
  // get the context
  return useContext(GlobalizationContextUpdater)
}

interface IGlobalizationContextProviderProps {
  children?: ReactElement
}

const GlobalizationContextProvider = ({
  children
}: IGlobalizationContextProviderProps) => {
  // the value that will be given to the context
  const [globalization, setGlobalization] = useState<
    IGlobalization | undefined
  >()
  const [countryPromise, setCountryPromise] = useState<
    Promise<any> | undefined
  >()

  const fetchCountry = useCallback(async () => {
    // check Globalization
    if (!globalization) return

    // check loaded data
    if (
      globalization.countries &&
      globalization.culture === globalizationService.GetCurrentCulture()
    ) {
      return
    }

    if (countryPromise) {
      await countryPromise
      return
    }

    var promise = PromiseUtility.onErrorDisplayToast(
      localizationService.getCountries().then((countries) =>
        setGlobalization({
          ...globalization,
          countries: countries
        })
      )
    ).finally(() => {
      setCountryPromise(undefined)
    })

    setCountryPromise(promise)

    await promise
  }, [globalization, countryPromise])

  const [updater, setUpdater] = useState<
    IGlobalizationContextUpdater | undefined
  >({
    updateCountries: fetchCountry
  })

  useEffect(() => {
    setUpdater({
      updateCountries: fetchCountry
    })
  }, [fetchCountry])

  let { culture } = useParams()

  // set culture data
  useEffect(() => {
    const currentCulture = globalizationService.GetCurrentCulture()

    if (culture && culture !== currentCulture) {
      globalizationService.SetCurrentCulture(currentCulture)
    }

    setGlobalization({
      culture: currentCulture
    })
  }, [culture])

  return (
    // the Providers gives access to the context to its children
    <GlobalizationContext.Provider value={globalization}>
      <GlobalizationContextUpdater.Provider value={updater}>
        {children}
      </GlobalizationContextUpdater.Provider>
    </GlobalizationContext.Provider>
  )
}

export {
  GlobalizationContextProvider,
  useGlobalizationContext,
  useGlobalizationContextUpdater
}
