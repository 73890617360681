import IOrganization from 'interfaces/organizations/IOrganization'
import OrganizationService from './OrganizationService'

interface DomainServiceCallback {
  subscription: Number
  callback: (
    currentOrganization: IOrganization | undefined,
    organizations: IOrganization[]
  ) => void
}

export class DomainService {
  _callbacks = Array<DomainServiceCallback>()
  _nextSubscriptionId = 0
  _currentOrganization: IOrganization | undefined = undefined
  _organizations: IOrganization[] = []

  /**
   * Returns the current active organization
   * @returns
   */
  GetCurrentOrganization(): IOrganization | undefined {
    return this._currentOrganization
  }

  GetOrganizations(): IOrganization[] {
    return this._organizations
  }

  async retrieveOrganizations() {
    await OrganizationService.getOrganizations().then((response) => {
      this._organizations = response
      if (this._organizations && this._organizations.length > 0) {
        const storedOrganization = localStorage.getItem(
          'vic-storage-organization'
        )

        let organizationIndex = 0

        if (storedOrganization) {
          organizationIndex = this._organizations.findIndex((value) => {
            return storedOrganization === value.id
          })

          if (organizationIndex < 0) {
            organizationIndex = 0
          }
        }

        this.updateOrganization(this._organizations[organizationIndex])
      }
    })
  }

  updateOrganization(organization: IOrganization) {
    if (
      this._currentOrganization &&
      this._currentOrganization.id &&
      organization.id &&
      this._currentOrganization.id === organization.id
    ) {
      return
    }

    this._currentOrganization = organization

    if (organization && organization.id) {
      localStorage.setItem('vic-storage-organization', organization.id)
    }

    this.notifySubscribers()
  }

  subscribe(
    callback: (
      currentOrganization: IOrganization | undefined,
      organizations: IOrganization[]
    ) => void
  ) {
    this._callbacks.push({
      callback,
      subscription: this._nextSubscriptionId++
    })
    return this._nextSubscriptionId - 1
  }

  unsubscribe(subscriptionId: number) {
    const subscriptionIndex = this._callbacks
      .map((element, index) =>
        element.subscription === subscriptionId
          ? { found: true, index }
          : { found: false }
      )
      .filter((element) => element.found)
    if (subscriptionIndex.length !== 1) {
      throw new Error(
        `Found an invalid number of subscriptions ${subscriptionIndex.length}`
      )
    }
    if (subscriptionIndex[0].index) {
      this._callbacks.splice(subscriptionIndex[0].index, 1)
    }
  }

  notifySubscribers() {
    for (let i = 0; i < this._callbacks.length; i++) {
      const callback = this._callbacks[i].callback
      callback(this._currentOrganization, this._organizations)
    }
  }

  static instance() {
    return domainService
  }
}

const domainService = new DomainService()

export default domainService
