import { AxiosResponse } from 'axios'
import ILicense from 'interfaces/licensing/ILicense'
import { ApplicationAPI } from '../ApiConstants'
import RequestService from './RequestService'

export default class LicenseService {
  static async getLicenses<R = ILicense[]>(): Promise<R> {
    return await RequestService.Get(ApplicationAPI.Licenses.GetAllLicenses)
  }

  static async assignDevice<T = any, R = AxiosResponse<T>>(
    deviceId: string,
    licenseId: string
  ): Promise<R> {
    return await RequestService.Post(ApplicationAPI.Licenses.AssignDevice, {
      licenseId,
      deviceId
    })
  }
}
