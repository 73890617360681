import * as process from 'process'

export const IsProduction = process.env.NODE_ENV === 'production'
export const IsDevelopment = process.env.NODE_ENV === 'development'
export const IsTest = process.env.NODE_ENV === 'test'

const EnvironmentVariables = {
  IsHomeEnvironmentEnabled: IsProduction ? true : true,
  IsAboutUsEnvironmentEnabled: IsProduction ? true : true,
  IsServicesEnvironmentEnabled: IsProduction ? true : true,
  IsPricingEnvironmentEnabled: IsProduction ? false : true,
  IsContactUsEnvironmentEnabled: IsProduction ? true : true,
  IsTermsOfUseEnvironmentEnabled: IsProduction ? true : true,
  IsPrivacyAndPolicesEnvironmentEnabled: IsProduction ? true : true,
  IsUserAllowedToAuthenticate: IsProduction ? true : true,
  IsUserAllowedToRegister: IsProduction ? true : true,
  IsUsageAgreementEnvironmentEnabled: IsProduction ? true : true,

  PayPal: {
    ClientId: IsProduction
      ? 'AYtVmnSmx7t_FSc-gFcySdePCKSDveQovgfptQro8F6Hn9u6PwTJvLBsvTSO1XzjIkCp5aRvvGOOVOgh'
      : 'Af6qY0bZDti9vSPdX3GAVgO8IgE0o0mw76rioeKmgYMBlAR26Fc_kz55OeWjwXZvfSKT2qBBolEffq05'
  },

}

export default EnvironmentVariables
