export class GlobalizationService {
  _currentCulture: string | undefined = undefined

  GetCurrentCulture(): string {
    if (this._currentCulture) {
      return this._currentCulture
    }

    this._currentCulture = navigator.language

    return this._currentCulture
  }

  SetCurrentCulture(culture: string) {
    this._currentCulture = culture
  }

  static instance() {
    return globalizationService
  }
}

const globalizationService = new GlobalizationService()

export default globalizationService
