const ApplicationRoutes = {
  Home: '/',
  Landing: '/landing',
  AboutUs: '/aboutus',
  Pricing: '/pricing',
  Faqs: '/faqs',
  ContactUs: '/contactus',
  Error: '/error',
  AccessDenied: '/access-denied',
  Services: '/services',
  PrivacyAndPolices: '/privacy-and-policies',
  TermsOfUse: '/terms-of-use',
  UsageAgreement: '/usage-agreement',
  NewsletterSubscriptionRequested: '/newsletter-subscription-requested',
  Account: {
    Register: '/account/register',
    SignIn: '/account/signin',
    SignOut: '/account/signout',
    AccountProcessed: '/account/request-processed',
    ConfirmAccount: '/account/confirm-account',
    ConfirmEmailChange: '/account/confirm-email-change',
    AccountConfirmed: '/account/account-confirmed',
    EmailChanged: '/account/email-changed',
    ResetPassword: '/account/reset-password',
    PasswordResetted: '/account/password-reset-successfully'
  }
}

export default ApplicationRoutes
