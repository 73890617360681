import ProfileService from 'api/services/ProfileService'
import IProfile from 'interfaces/user/IProfile'
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  ReactElement
} from 'react'

// create contexts
const ProfileContext = createContext<IProfile | undefined>(undefined)
const ProfileContextUpdater = createContext<IProfileContextUpdater | undefined>(
  undefined
)

// context consumer hook
const useProfileContext = (): IProfile | undefined => {
  // get the context
  return useContext(ProfileContext)
}

interface IProfileContextUpdater {
  updateProfileData: VoidFunction
}

// context consumer hook
const useProfileContextUpdater = (): IProfileContextUpdater | undefined => {
  // get the context
  return useContext(ProfileContextUpdater)
}

interface IProfileContextProviderProps {
  children?: ReactElement
}

const ProfileContextProvider = ({ children }: IProfileContextProviderProps) => {
  // the value that will be given to the context
  const [profile, setProfile] = useState<IProfile | undefined>()

  const fetchProfile = useCallback(() => {
    ProfileService.getProfileData()
      .then((result) => setProfile(result))
      .catch((error) => console.log(error))
  }, [])

  const [updater] = useState<IProfileContextUpdater | undefined>({
    updateProfileData: fetchProfile
  })

  // fetch profile data
  useEffect(() => {
    fetchProfile()
  }, [fetchProfile])

  return (
    // the Providers gives access to the context to its children
    <ProfileContext.Provider value={profile}>
      <ProfileContextUpdater.Provider value={updater}>
        {children}
      </ProfileContextUpdater.Provider>
    </ProfileContext.Provider>
  )
}

export { ProfileContextProvider, useProfileContext, useProfileContextUpdater }
