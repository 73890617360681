import { CdnAddress } from 'api/ApiConstants'
import { usePayment } from 'middlewares/PaymentContext'
import React, { useCallback, useState } from 'react'
import { NavLink } from 'react-router-dom'
import ManagementRoutes from 'routes/ManagementRoutes'

interface IPaymentCancelledProps {
  onClose: VoidFunction
}

export default function PaymentCancelled({ onClose }: IPaymentCancelledProps) {
  const [hidden, setHidden] = useState(false)

  const payment = usePayment()

  const close = useCallback(() => {
    setHidden(true)
    const timer = setTimeout(function () {
      clearTimeout(timer)
      payment?.resetPayment()
      if (onClose != null) {
        onClose()
      }
    }, 600)
  }, [onClose, payment])

  return (
    <div
      tabIndex={-1}
      className={`overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-modal h-full flex flex-col-reverse bg-black/[.3] ${
        hidden
          ? 'wow animate__animated animate__fadeOut animated delay-75'
          : 'wow animate__animated animate__fadeIn animated'
      }  `}
      aria-modal="true"
      role="dialog"
    >
      <div
        className={`relative w-full h-5/6 sm:h-4/5 bg-white rounded-lg shadow transition-all ${
          hidden
            ? 'wow animate__animated animate__fadeOutDown  animated'
            : 'wow animate__animated animate__fadeInUp animated'
        }`}
        style={{ animationDelay: '0.3s' }}
      >
        <div className="flex flex-col items-center h-full justify-center">
          <img
            className="max-w-[15rem] md:max-w-sm 2xl:max-w-xl mx-auto"
            src={`${CdnAddress}/static/assets/imgs/illustrations/payment-error.svg`}
            alt="payment successful"
          />
          <p className="text-xl md:text-3xl 2xl:text-4xl text-red-500 font-bold font-heading text-center">
            Pagamento cancellato
          </p>
          <p className="mb-4 text-gray-500 leading-relaxed wow animate__animatedanimated animate__fadeIn text-xs md:text-sm 2xl:text-lg text-center font-semibold">
            La procedura di pagamento è stata cancellata.
            <br />
            Se hai cambiato idea torna al carrello, riproponi il pagamento ed
            inizia anche tu a far parte di NovaAds.
          </p>
          <div className="flex flex-col-reverse md:flex-row md:items-center md:justify-center">
            <NavLink
              to={ManagementRoutes.Shopwindows}
              className="py-4 px-8 mb-4 sm:mr-3 text-xs text-gray-500 hover:text-gray-800 bg-gray-50 hover:bg-gray-100 text-center font-semibold leading-none rounded wow animate__animated animate__fadeIn"
              data-wow-delay=".5s"
              onClick={() => {
                payment?.resetPayment()
              }}
            >
              Torna alla Dashboard
            </NavLink>
            <button
              className="py-4 px-8 mb-4 text-xs text-white bg-primary-500 hover:bg-primary-500 text-center font-semibold leading-none  rounded wow animate__animated animate__fadeIn"
              data-wow-delay=".6s"
              onClick={close}
            >
              Torna al carrello
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
