import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Logout from '@mui/icons-material/Logout'
import Box from '@mui/material/Box'
import authService, { AuthorizeService } from 'api/services/AuthorizeService'
import { useNavigate } from 'react-router-dom'
import ApplicationRoutes from 'routes/ApplicationRoutes'
import IAuthenticatedUserInformation from 'interfaces/authentication/IAuthenticatedUserInformation'
import ManagementRoutes from 'routes/ManagementRoutes'
import INotification from 'interfaces/account/INotification'
import Notifications from 'components/status/Notifications'

export default function AccountMenu() {
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [user, setUser] = React.useState<
    IAuthenticatedUserInformation | null | undefined
  >(null)

  React.useEffect(() => {
    const _subscription = authService.subscribe(
      async () => await populateState()
    )
    populateState()

    return () => {
      authService.unsubscribe(_subscription)
    }
  }, [])

  const populateState = async () => {
    const user = await authService.getUser()
    setUser(user)
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = async () => {
    try {
      await AuthorizeService.instance.signOut()
    } catch (error) {}
    navigate(ApplicationRoutes.Home)
  }

  const stringAvatar = (
    user: IAuthenticatedUserInformation | null | undefined
  ) => {
    if (user != null && user.firstName && user.lastName) {
      const name = `${user.firstName} ${user.lastName}`

      return {
        sx: {
          bgcolor: stringToColor(name),
          width: 40,
          height: 40
        },
        children: `${user.firstName[0]}${user.lastName[0]}`
      }
    } else if (user && user.email) {
      return {
        sx: {
          bgcolor: stringToColor(user.email),
          width: 40,
          height: 40
        },
        children: `${user.email[0].toUpperCase()}`
      }
    } else {
      return {
        sx: {
          width: 40,
          height: 40
        }
      }
    }
  }

  const stringToColor = (string: string) => {
    let hash = 0
    let i

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = '#'

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff
      color += `00${value.toString(16)}`.slice(-2)
    }
    /* eslint-enable no-bitwise */

    return color
  }

  const handleMyProfile = async () => {
    navigate(ManagementRoutes.User.Profile)
  }

  return (
    <div className='flex flex-row'>
      <Box
        sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}
      >
        <Notifications />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account">
          <IconButton
            onClick={handleClick}
            size="medium"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            {user && user.picture ? (
              <>
                <Avatar
                  sx={{ width: 40, height: 40 }}
                  src={user?.picture}
                  alt="Profilo"
                ></Avatar>
              </>
            ) : (
              <>
                <Avatar {...stringAvatar(user)}></Avatar>
              </>
            )}
          </IconButton>
        </Tooltip>
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.40))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 40,
              height: 40,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleMyProfile}>
          <Avatar /> Profilo
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Esci
        </MenuItem>
      </Menu>
    </div>
  )
}
