import React, { ReactElement } from 'react'
import { NavLink } from 'react-router-dom'

interface SidebarItemProps {
  to: string
  icon: ReactElement
  content: string
  isMenuOpen?: boolean
}

export default function SidebarItem({
  to = '/',
  icon,
  content = '',
  isMenuOpen
}: SidebarItemProps) {
  return (
    <li
      className={`items-center ${
        isMenuOpen
          ? 'self-stretch text-left wow animate__animated animate__fadeIn'
          : 'self-center self-stretch text-center xl:text-left'
      }`}
    >
      <NavLink
        className={({ isActive }) =>
          'py-4 uppercase block hover-up-2 no-underline text-white hover:text-primary-500 hover:bg-primary-100' +
          (isActive
            ? ' bg-primary-500' : '')
        }
        to={to}
      >
        {icon && <div className="ml-4 mr-4 inline">{icon}</div>}
        <div
          className={`${
            isMenuOpen
              ? 'inline visible'
              : 'invisible hidden xl:inline xl:visible'
          }`}
        >
          {content}
        </div>
      </NavLink>
    </li>
  )
}
