import { AxiosResponse } from 'axios'
import IPermittedFormats from 'interfaces/medias/formats/IPermittedFormats'
import IMediaElement from 'interfaces/medias/IMediaElement'
import { IMediaElementInputModel } from 'interfaces/medias/IMediaElementInputModel'
import { ApplicationAPI } from '../ApiConstants'
import RequestService from './RequestService'

export default class MediaService {
  static async getPermittedFormats<R = IPermittedFormats>(): Promise<R> {
    return await RequestService.Get(
      `${ApplicationAPI.Medias}/allowed-formats`
    )
  }

  static async getMedias<R = IMediaElement[]>(): Promise<R> {
    return await RequestService.Get(ApplicationAPI.Medias)
  }

  static async getMedia<R = IMediaElement>(id?: string): Promise<R> {
    return await RequestService.Get(ApplicationAPI.Medias, id)
  }

  static async deleteMedia<T = any, R = AxiosResponse<T>>(
    id: string
  ): Promise<R> {
    return await RequestService.Delete(ApplicationAPI.Medias, id)
  }

  static async registerMedia<T = any, R = AxiosResponse<T>>(
    data: File[],
    onUploadProgress?: (progressEvent: any) => void
  ): Promise<R> {
    const headers = {
      'Content-Type': 'multipart/form-data'
    }

    const formData = new FormData()

    data.forEach((element) => {
      formData.append(element.name, element)
    })

    return await RequestService.Post(
      ApplicationAPI.Medias,
      formData,
      headers,
      undefined,
      {
        onUploadProgress
      }
    )
  }

  static async updateMedia<T = any, R = AxiosResponse<T>>(
    data: IMediaElementInputModel
  ): Promise<R> {
    const headers = {
      'Content-Type': 'multipart/form-data'
    }

    const formData = new FormData()

    if (!data || !data.name || !data.id) {
      throw new Error('Illegal Argument, unable to update device')
    }

    formData.set('id', data.id)
    formData.set('name', data.name)

    if (data.description) formData.set('description', data.description)

    return await RequestService.Put(
      `${ApplicationAPI.Medias}/${data.id}`,
      formData,
      headers
    )
  }
}
