import { CdnAddress } from 'api/ApiConstants'
import { usePayment } from 'middlewares/PaymentContext'
import React from 'react'
import { NavLink } from 'react-router-dom'
import ManagementRoutes from 'routes/ManagementRoutes'

interface IPaymentSuccessProps {
  transactionId: string
  amount: number
}

export default function PaymentSuccess({
  transactionId,
  amount
}: IPaymentSuccessProps) {
  const payment = usePayment()

  return (
    <div
      tabIndex={-1}
      className={`overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-modal h-full flex flex-col-reverse bg-black/[.3] wow animate__animated animate__fadeIn animated`}
      aria-modal="true"
      role="dialog"
    >
      <div
        className={`relative w-full h-5/6 md:h-4/5 bg-white rounded-lg shadow transition-all wow animate__animated animate__fadeInUp animated`}
        style={{ animationDelay: '0.3s' }}
      >
        <div className="flex flex-col items-center h-full justify-center">
          <img
            className="max-w-[15rem] md:max-w-sm 2xl:max-w-xl mx-auto"
            src={`${CdnAddress}/static/assets/imgs/illustrations/payment-success.svg`}
            alt="payment successful"
          />
          <p className="text-base md:text-2xl 2xl:text-3xl text-gray-500 font-normal text-center">
            {amount.toFixed(2)}
            {' €'}
          </p>
          <p className="text-base md:text-2xl 2xl:text-3xl text-green-500 font-bold font-heading text-center">
            Pagamento riuscito
          </p>
          <p className="mb-4 text-xs md:text-sm 2xl:text-lg text-gray-500 text-center font-semibold">
            numero transazione:{' '}
            <span className="text-primary-500">{transactionId}</span>
          </p>
          <p className="mb-2 text-gray-500 leading-relaxed wow animate__animatedanimated animate__fadeIn text-xs md:text-sm 2xl:text-lg text-center font-semibold">
            Garanzia di prenotazione, presto riceverà la notifica di avvenuto
            pagamento.
            <br /> Prosegui nell'area personale ed assegna le tue licenze
          </p>
          <div className="flex flex-col-reverse md:flex-row md:items-center md:justify-center">
            <NavLink
              to={ManagementRoutes.Shopwindows}
              className="py-4 px-8 mb-4 sm:mr-3 text-xs text-gray-500 hover:text-gray-800 bg-gray-50 hover:bg-gray-100 text-center font-semibold leading-none rounded wow animate__animated animate__fadeIn"
              data-wow-delay=".5s"
              onClick={() => {
                payment?.resetPayment()
              }}
            >
              Torna alla Dashboard
            </NavLink>
            <NavLink
              to={ManagementRoutes.User.Orders}
              className="py-4 px-8 mb-4 text-xs text-white bg-primary-500 hover:bg-primary-500 text-center font-semibold leading-none  rounded wow animate__animated animate__fadeIn"
              data-wow-delay=".6s"
              onClick={() => {
                payment?.resetPayment()
              }}
            >
              Visualizza i mei ordini
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  )
}
