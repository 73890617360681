import { Fragment, useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import ApplicationRoutes from 'routes/ApplicationRoutes'
import authService, { AuthorizeService } from 'api/services/AuthorizeService'
import ManagementRoutes from 'routes/ManagementRoutes'
import EnvironmentVariables from 'EnvironmentVariables'

interface ILoginMenuProps {
  loginClassName?: string
  signupClassName?: string
  logoutClassName?: string
}

interface ILoginMenuState {
  isAuthenticated: boolean
  isloading: boolean
}

export default function LoginMenu(props: ILoginMenuProps) {
  const [state, setState] = useState<ILoginMenuState>({
    isAuthenticated: false,
    isloading: true
  })

  const navigate = useNavigate()

  const populateState = async () => {
    try {
      const isAuthenticated = await authService.isAuthenticated()

      setState({
        isAuthenticated,
        isloading: false
      })
    } catch (error) {
      setState({
        isAuthenticated: false,
        isloading: false
      })
    }
  }

  useEffect(() => {
    const _subscription = authService.subscribe(() => populateState())

    populateState()
    return () => {
      if (_subscription) {
        authService.unsubscribe(_subscription)
      }
    }
  }, [])

  const signOut = async () => {
    try {
      await AuthorizeService.instance.signOut()
    } catch (error) {}
    navigate(ApplicationRoutes.Home)
  }

  if (!EnvironmentVariables.IsUserAllowedToAuthenticate) {
    return <></>
  }

  const { isAuthenticated, isloading } = state

  if (isloading) return <></>

  if (!isAuthenticated) {
    const registerPath = `${ApplicationRoutes.Account.Register}`
    const loginPath = `${ApplicationRoutes.Account.SignIn}`
    return (
      <Fragment>
        <NavLink
          to={loginPath}
          className={
            props.loginClassName
              ? props.loginClassName
              : 'btn-accent hover-up-2 no-underline'
          }
        >
          Accedi
        </NavLink>
        {EnvironmentVariables.IsUserAllowedToRegister && (
          <NavLink
            to={registerPath}
            className={
              props.signupClassName
                ? props.signupClassName
                : 'btn-primary hover-up-2 no-underline'
            }
          >
            Registrati
          </NavLink>
        )}
      </Fragment>
    )
  } else {
    return (
      <Fragment>
        <NavLink
          to={ManagementRoutes.Shopwindows}
          className={
            props.logoutClassName
              ? props.logoutClassName
              : 'btn-accent hover-up-2 no-underline'
          }
        >
          Dashboard
        </NavLink>

        <button
          onClick={signOut}
          className={
            props.logoutClassName
              ? props.logoutClassName
              : 'btn-accent hover-up-2 no-underline'
          }
        >
          Esci
        </button>
      </Fragment>
    )
  }
}
