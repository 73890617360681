import IAuthenticatedUserInformation from 'interfaces/authentication/IAuthenticatedUserInformation'
import AuthenticationService from 'api/services/AuthenticationService'
import ISignInInputModel from 'interfaces/account/ISignInInputModel'
import ErrorUtility from 'utilities/ErrorUtility'

interface AuthorizeServiceCallback {
  subscription: Number
  callback: () => void
}

export class AuthorizeService {
  _callbacks = Array<AuthorizeServiceCallback>()
  _nextSubscriptionId = 0
  _user: IAuthenticatedUserInformation | null | undefined
  _isAuthenticated: boolean | undefined

  async isAuthenticated() {
    if (!!this._isAuthenticated) {
      return this._isAuthenticated
    }

    this._isAuthenticated = await AuthenticationService.isSignedIn()

    return this._isAuthenticated
  }

  async getUser() {
    if (this._user) {
      return this._user
    }

    if (await this.isAuthenticated()) {
      try {
        this._user = await AuthenticationService.getUserInfo()
      } catch (error) {
        ErrorUtility.LogErrorIfNotInProduction(error)
      }
    }

    return this._user
  }

  async getUserRoles() {
    const user = await this.getUser()
    return user && user.roles
  }

  async isInRole(role: string) {
    const userRoles = await this.getUserRoles()
    if (userRoles) {
      if (userRoles.length !== 0) {
        const exist = userRoles.includes(role)
        return exist
      }
    }
    return false
  }

  async signIn(signInData: ISignInInputModel) {
    try {
      await AuthenticationService.signIn(signInData)
      const user = await this.getUser()
      this.updateState(user)
    } catch (error) {
      console.log(
        'Authentication.SignIn error: ',
        ErrorUtility.GetErrorMessage(error)
      )
      throw error
    }
  }

  async signOut(updatestateOnly?: boolean) {
    try {
      if (!updatestateOnly) {
        await AuthenticationService.signOut()
      }
      this.updateState(undefined)
    } catch (error) {
      console.log(
        'Authentication.SignOut error: ',
        ErrorUtility.GetErrorMessage(error)
      )
      throw error
    }
  }

  updateState(user: IAuthenticatedUserInformation | null | undefined) {
    this._user = user
    this._isAuthenticated = !!this._user
    this.notifySubscribers()
  }

  subscribe(callback: () => void) {
    this._callbacks.push({
      callback,
      subscription: this._nextSubscriptionId++
    })
    return this._nextSubscriptionId - 1
  }

  unsubscribe(subscriptionId: number) {
    const subscriptionIndex = this._callbacks
      .map((element, index) =>
        element.subscription === subscriptionId
          ? { found: true, index }
          : { found: false, index: undefined }
      )
      .filter((element) => element.found)
    if (subscriptionIndex.length !== 1) {
      throw new Error(
        `Found an invalid number of subscriptions ${subscriptionIndex.length}`
      )
    }

    if (subscriptionIndex[0].index) {
      this._callbacks.splice(subscriptionIndex[0].index, 1)
    }
  }

  notifySubscribers() {
    for (let i = 0; i < this._callbacks.length; i++) {
      const callback = this._callbacks[i].callback
      callback()
    }
  }

  static get instance() {
    return authService
  }
}

const authService = new AuthorizeService()

export default authService

export const Roles = {
  Administrator: 'Administrator',
  ContentManager: 'ContentManager'
}
