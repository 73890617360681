import { ApplicationAPI } from '../ApiConstants'
import RequestService from './RequestService'
import ICountry from 'interfaces/localization/ICountry'

export default class LocalizationService {
  static getCountries<R = ICountry[]>(): Promise<R> {
    return RequestService.Get(
      `${ApplicationAPI.Localization.CountriesEndpoint}`
    )
  }
}
