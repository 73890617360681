import { CircularProgress } from '@mui/material'
import React, { Component } from 'react'
import SyncLoader from 'react-spinners/SyncLoader'

export enum State {
  Unsetted = 0,
  Nothing = 1,
  Disabled,
  Loading,
  Error,
  Success
}

type ProgressButtonProps = typeof ProgressButton.defaultProps & {
  controlled: Boolean
  durationError: Number
  durationSuccess: Number
  form?: string | undefined
  onClick?: (e: any) => Promise<any>
  onError?: (e: any) => Function
  onSuccess?: (e: any) => Function
  state: State
  type: 'submit' | 'reset' | 'button' | undefined
  shouldAllowClickOnLoading: Boolean
  children: any
  normalStateClasses?: string | undefined
  percentage?: number
}

interface ProgressButtonState {
  currentState: State
}

export default class ProgressButton extends Component<
  ProgressButtonProps,
  ProgressButtonState
> {
  static defaultProps = {
    controlled: false,
    durationError: 1200,
    durationSuccess: 500,
    shouldAllowClickOnLoading: false,
    state: State.Unsetted,
    normalStateClasses: 'bg-primary-500 hover:bg-primary-700',
    indeterminate: true
  }

  _timeout: NodeJS.Timeout | undefined

  constructor(props: ProgressButtonProps) {
    super(props)
    this.state = {
      currentState:
        props.state !== (State.Unsetted as State) ? props.state : State.Nothing
    }

    this.handleClick = this.handleClick.bind(this)
    this.handlePromise = this.handlePromise.bind(this)
    this.loading = this.loading.bind(this)
    this.notLoading = this.notLoading.bind(this)
    this.enable = this.enable.bind(this)
    this.disable = this.disable.bind(this)
    this.unsetted = this.unsetted.bind(this)
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
  }

  componentDidUpdate(prevousProps: ProgressButtonProps) {
    if (prevousProps.state === this.props.state) {
      return
    }
    switch (this.props.state) {
      case State.Success:
        this.success()
        return
      case State.Error:
        this.error()
        return
      case State.Loading:
        this.loading()
        return
      case State.Disabled:
        this.disable()
        return
      case State.Nothing:
        this.notLoading()
        return
      case State.Unsetted:
        this.unsetted()
        return
      default:
        return
    }
  }

  componentWillUnmount() {
    clearTimeout(this._timeout)
  }

  render() {
    const { children, type, form, state, indeterminate, percentage } =
      this.props

    let CurrentState = State.Nothing

    if (state || (state as State) !== (State.Unsetted as State)) {
      CurrentState = state
    } else {
      if (this.state.currentState !== (State.Unsetted as State)) {
        CurrentState = this.state.currentState
      }
    }

    if (CurrentState === (State.Loading as State)) {
      return (
        <button className="transition duration-300 ease-in-out block w-full p-4 text-center text-xs text-white font-semibold leading-none cursor-progress">
          {indeterminate ? (
            <SyncLoader color="#3B82F6" size={15} loading />
          ) : (
            <CircularProgress variant="determinate" value={percentage} />
          )}
        </button>
      )
    } else {
      const buttonStyle = `transition duration-300 ease-in-out block w-full p-4 text-center text-xs text-white font-semibold leading-none rounded
      ${
        CurrentState === (State.Nothing as State)
          ? '  transform hover:-translate-y-1 ' + this.props.normalStateClasses
          : ''
      } 
      ${CurrentState === (State.Error as State) ? ' bg-red-500' : ''}  
      ${CurrentState === (State.Success as State) ? ' bg-green-500' : ''}
      ${
        CurrentState === (State.Disabled as State)
          ? ' bg-gray-300 cursor-not-allowed'
          : ''
      }`

      return (
        <button
          disabled={CurrentState === (State.Disabled as State)}
          type={type}
          form={form}
          className={buttonStyle}
          onClick={this.handleClick}
        >
          {children}
        </button>
      )
    }
  }

  handleClick(e: any) {
    if (this.props.onClick) {
      e.preventDefault()
    }

    const shouldAllowClick =
      (this.props.shouldAllowClickOnLoading ||
        this.state.currentState !== State.Loading) &&
      this.state.currentState !== State.Disabled

    if (this.props.controlled && shouldAllowClick) {
      if (this.props.onClick) {
        this.props.onClick(e)
      }
      return true
    }

    if (shouldAllowClick) {
      this.loading()
      this._timeout = setTimeout(() => {
        clearTimeout(this._timeout)

        if (this.props.onClick) {
          const ret = this.props.onClick(e)
          if (ret) {
            this.handlePromise(ret)
          } else {
            this.notLoading()
          }
        } else {
          this.notLoading()
        }
      }, 500)
    } else {
      e.preventDefault()
    }
  }

  handlePromise(promise: Promise<any>) {
    if (promise) {
      promise
        .then(() => {
          this.success()
        })
        .catch((err) => {
          this.error(null, err)
        })
    }
  }

  loading() {
    this.setState({ currentState: State.Loading })
  }

  notLoading() {
    this.setState({ currentState: State.Nothing })
  }

  enable() {
    this.setState({ currentState: State.Nothing })
  }

  disable() {
    this.setState({ currentState: State.Disabled })
  }

  unsetted() {
    this.setState({ currentState: State.Unsetted })
  }

  success(callback?: Function | null, dontRemove?: boolean) {
    this.setState({ currentState: State.Success })
    this._timeout = setTimeout(() => {
      clearTimeout(this._timeout)
      if (!dontRemove) {
        this.setState({ currentState: State.Nothing })
      }
      callback = callback || this.props.onSuccess
      if (typeof callback === 'function') {
        callback()
      }
    }, this.props.durationSuccess)
  }

  error(callback?: Function | null, err?: any) {
    this.setState({ currentState: State.Error })
    this._timeout = setTimeout(() => {
      clearTimeout(this._timeout)
      this.setState({ currentState: State.Nothing })
      callback = callback || this.props.onError
      if (typeof callback === 'function') {
        callback(err)
      }
    }, this.props.durationError)
  }
}
