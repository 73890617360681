import { CdnAddress } from 'api/ApiConstants'
import LoginMenu from 'components/authorization/LoginMenu'
import EnvironmentVariables from 'EnvironmentVariables'
import React, { useState, useEffect, useCallback } from 'react'
import { NavLink } from 'react-router-dom'
import ApplicationRoutes from 'routes/ApplicationRoutes'
import HeaderNavItem from './HeaderNavItem'
import AccountMenu from 'components/account/AccountMenu'

interface HeaderProps {
  handleHidden: () => void
  inDashboard: boolean
}

export default function Header({ handleHidden, inDashboard }: HeaderProps) {
  const [scroll, setScroll] = useState(false)

  const onScroll = useCallback(() => {
    const scrollCheck = window.scrollY > 100
    if (scrollCheck !== scroll) {
      setScroll(scrollCheck)
    }
  }, [scroll])

  useEffect(() => {
    document.addEventListener('scroll', onScroll)
    return () => {
      document.removeEventListener('scroll', onScroll)
    }
  }, [onScroll])

  return (
    <header
      className={
        scroll
          ? 'bg-white sticky z-40 top-0 shadow-md sticky-bar py-2 stick z-'
          : 'bg-white sticky z-40 top-0 shadow-md sticky-bar py-2'
      }
    >
      <div className="container bg-transparent">
        <nav className="bg-transparent flex justify-between items-center py-3">
          <div className="text-3xl font-semibold leading-none">
            <NavLink to={ApplicationRoutes.Home}>
              <img
                className="h-16"
                src={`${CdnAddress}/static/assets/imgs/logos/novaads-logo-text.svg`}
                alt="Logo"
              />
            </NavLink>
          </div>
          <ul className="hidden lg:flex lg:items-center lg:w-auto lg:space-x-12">
            {EnvironmentVariables.IsHomeEnvironmentEnabled && (
              <HeaderNavItem to={ApplicationRoutes.Home} title="Home" />
            )}

            {EnvironmentVariables.IsServicesEnvironmentEnabled && (
              <HeaderNavItem to={ApplicationRoutes.Services} title="Servizi" />
            )}

            {EnvironmentVariables.IsPricingEnvironmentEnabled && (
              <HeaderNavItem to={ApplicationRoutes.Pricing} title="Prezzi" />
            )}

            {EnvironmentVariables.IsContactUsEnvironmentEnabled && (
              <HeaderNavItem to={ApplicationRoutes.ContactUs} title="Contatti" />
            )}
          </ul>
          <div className="hidden lg:block">
            {inDashboard ? (
              <AccountMenu />
            ) : (
              <LoginMenu />
            )}
          </div>
          <div className="lg:hidden">
            <button
              className="navbar-burger flex items-center py-2 px-3 text-primary-500 hover:text-primary-700 rounded border border-primary-200 hover:border-primary-300"
              onClick={() => {
                if (handleHidden) {
                  handleHidden()
                }
              }}
            >
              <svg
                className="fill-current h-4 w-4"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Mobile menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
              </svg>
            </button>
          </div>
        </nav>
      </div>
    </header>
  )
}