import { ApplicationAPI } from '../ApiConstants'
import RequestService from './RequestService'
import ICreateAccountInputModel from 'interfaces/account/ICreateAccountInputModel'

export default class AccountService {
  /**
   * Request the creation of a new account and send a message for confirmation
   * @param createAccountModel, The model containing the information of the account to create
   * @throws an @see {Exception} if the request is unsuccessful
   */
  static createAccount(
    createAccountModel: ICreateAccountInputModel
  ): Promise<void> {
    if (
      !createAccountModel.Organization ||
      createAccountModel.Organization === ''
    ) {
      createAccountModel.Organization = 'Personale'
    }

    return RequestService.Post(
      ApplicationAPI.Account.Create,
      createAccountModel,
      undefined,
      true
    )
  }

  /**
   * Confirm the identity of the account and proceed with the creation
   * @param email, The account email
   * @param code, The authorization token
   * @returns a @see {Promise<void>}
   * @throws an @see {Exception} if the request is unsuccessful
   */
  static confirmAccount(email: string, code: string) {
    return RequestService.Post(
      ApplicationAPI.Account.ConfirmAccount,
      {
        email: email,
        confirmationToken: code
      },
      undefined,
      true
    )
  }

  /**
   * Reset the user’s password to a new one
   * @param password, The new password to set
   * @param passwordConfirmation, The new password Confirmation
   * @param code, The authorization token
   * @throws an @see {Exception} if the request is unsuccessful
   */
  static resetPassword(
    password: string,
    passwordConfirmation: string,
    code: string
  ): Promise<void> {
    return RequestService.Post(
      ApplicationAPI.Account.ResetPassword,
      {
        password: password,
        confirmPassword: passwordConfirmation,
        code: code
      },
      undefined,
      true
    )
  }

  /**
   * Change the user’s password to a new one
   * @param currentPassword, Current password of the user
   * @param newPassword, The new password to set
   * @param newPasswordCnfirmation, The new password Confirmation
   * @throws an @see {Exception} if the request is unsuccessful
   */
  static changePassword(
    currentPassword: string,
    newPassword: string,
    newPasswordConfirmation: string
  ): Promise<void> {
    return RequestService.Post(ApplicationAPI.Account.ChangePassword, {
      oldPassword: currentPassword,
      newPassword: newPassword,
      confirmPassword: newPasswordConfirmation
    })
  }

  /**
   * Request email change and send a message for confirmation
   * @param newEmail, The new email to set
   * @throws an @see {Exception} if the request is unsuccessful
   */
  static changeEmail(newEmail: string): Promise<void> {
    return RequestService.Post(ApplicationAPI.Account.ChangeEmail, {
      newEmail: newEmail
    })
  }

  /**
   * Delete the account and all data linked to it
   * @param password, The password of the user
   * @throws an @see {Exception} if the request is unsuccessful
   */
  static deleteAccount(password: string): Promise<void> {
    return RequestService.Post(ApplicationAPI.Account.Delete, {
      password
    })
  }

  /**
   * Authorize the change of the email
   * @param email, The confirm email
   * @param code, The token to authorize the change
   * @param userId, The user id of the server
   * @throws an @see {Exception} if the request is unsuccessful
   * @returns a @see {Promise}
   */
  static confirmEmailChange(
    email: string,
    code: string,
    userId: string
  ): Promise<void> {
    return RequestService.Post(
      ApplicationAPI.Account.ConfirmEmailChange,
      {
        email,
        code,
        userId
      },
      undefined,
      true
    )
  }
}
