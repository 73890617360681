import React, { Component } from 'react'

import { Navigate } from 'react-router-dom'
import ApplicationRoutes from 'routes/ApplicationRoutes'
import { QueryParameterNames, WebServerAddress } from 'api/ApiConstants'
import authService from 'api/services/AuthorizeService'
import Preloader from 'components/loaders/Preloader'
import { Roles } from 'api/Roles'

interface IAdministrationRouteProps {
  children?: any
}

interface IAdministrationRouteState {
  ready: boolean
  authenticated: boolean
  isAdministrator: boolean
}

export default class AdministrationRoute extends Component<
  IAdministrationRouteProps,
  IAdministrationRouteState
> {
  _subscription: number | undefined

  constructor(props: any) {
    super(props)

    this.state = {
      ready: false,
      authenticated: false,
      isAdministrator: false
    }
  }

  componentDidMount() {
    this._subscription = authService.subscribe(
      async () => await this.authenticationChanged()
    )
    this.populateAuthenticationState()
  }

  componentWillUnmount() {
    if (this._subscription) {
      authService.unsubscribe(this._subscription)
    }
  }

  render() {
    const { ready, authenticated, isAdministrator } = this.state
    let returnUrl = window.location.href

    if (!returnUrl) {
      returnUrl = WebServerAddress
    }

    const redirectUrl = `${ApplicationRoutes.Account.SignIn}?${
      QueryParameterNames.ReturnUrl
    }=${encodeURI(returnUrl)}`

    if (!ready) {
      return <Preloader />
    } else {
      if (authenticated && isAdministrator) {
        return this.props.children
      } else if (!authenticated) {
        return <Navigate to={redirectUrl} replace />
      } else if (!isAdministrator) {
        return <Navigate to={ApplicationRoutes.AccessDenied} replace />
      }
    }
  }

  async populateAuthenticationState() {
    const authenticated = await authService.isAuthenticated()
    const isAdministrator = await authService.isInRole(Roles.Administrator)
    this.setState({ ready: true, authenticated, isAdministrator })
  }

  async authenticationChanged() {
    this.setState({
      ready: false,
      authenticated: false,
      isAdministrator: false
    })
    await this.populateAuthenticationState()
  }
}
