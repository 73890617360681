import { ApplicationAPI } from '../ApiConstants'
import RequestService from './RequestService'
import ISignInInputModel from 'interfaces/account/ISignInInputModel'
import IAuthenticationProvider from 'interfaces/account/IAuthenticationProvider'
import IAuthenticatedUserInformation from 'interfaces/authentication/IAuthenticatedUserInformation'

export default class AuthenticationService {
 
  /**
   * Authenticate user with username and password
   * @param signInData The sign in information
   * @throws an @see {Exception} if the request is unsuccessful
   */
  static signIn(signInData: ISignInInputModel): Promise<void> {
    return RequestService.Post(
      ApplicationAPI.Authentication.SignIn,
      signInData,
      undefined,
      true
    )
  }

  /**
   * Get the current signed in user information
   * @throws an @see {Exception} if the request is unsuccessful
   */
  static getUserInfo<
  R = IAuthenticatedUserInformation
>(): Promise<R> {
    return RequestService.Get(
      ApplicationAPI.Authentication.GetUserInfo,
      undefined,
      true
    )
  }

  /**
   * Authenticate user with username and password
   * @throws an @see {Exception} if the request is unsuccessful
   */
  static isSignedIn(): Promise<boolean> {
    return RequestService.Get(
      ApplicationAPI.Authentication.IsSignedIn,
      undefined,
      true
    )
  }

  /**
   * Sign out the current user
   * @throws an @see {Exception} if the request is unsuccessful
   */
  static signOut(): Promise<void> {
    return RequestService.Post(
      ApplicationAPI.Authentication.SignOut,
      undefined,
      undefined,
      true
    )
  }

  /**
   * Returns the external oauth providers
   * @returns A collection of @see {IAuthenticationProvider}
   * @throws an @see {Exception} if the request is unsuccessful
   */
  static getExternalAuthenticationProviders<
    R = IAuthenticationProvider[]
  >(): Promise<R> {
    return RequestService.Get(
      ApplicationAPI.Authentication.ExternalAuthenticationSchemas,
      undefined,
      true
    )
  }
}
