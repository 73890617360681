import React from 'react'
import { ErrorMessage } from '@hookform/error-message'
import { FieldErrors, FieldValues } from 'react-hook-form'

type Props = {
  errors: FieldErrors<FieldValues>
  name: string
  className?: string | undefined
}

export default function ErrorMessageReporter({
  errors,
  name,
  className = 'text-xs mt-2 ml-2 mb-4 text-red-500 font-semibold text-left'
}: Props) {
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message, messages }) => {
        if (message) {
          return <p className={className}>{message}</p>
        } else if (messages) {
          return (
            <p className={className}>
              {Object.entries(messages).map(([type, message]) => (
                <>
                  {message}
                  <br />
                </>
              ))}
            </p>
          )
        } else {
          return <></>
        }
      }}
    />
  )
}
