import React from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

interface IOrganizationButtonProps {
  isMenuOpen: boolean
  onClick: () => any
}

export default function OrganizationButton({
  isMenuOpen,
  onClick
}: IOrganizationButtonProps) {
  const handleClick = () => {
    onClick()
  }

  return (
    <button
      id="other-button"
      className="w-full rounded-sm py-3 mt-2 uppercase block text-white hover:bg-primary-100 hover:text-primary-500"
      onClick={handleClick}
    >
      <div className="flex w-full flex-row justify-center px-4">
        <div
          className={`self-center ${
            isMenuOpen
              ? 'inline visible'
              : 'invisible hidden xl:inline xl:visible'
          }`}
        >
          seleziona organizzazione
        </div>
        <ArrowForwardIosIcon className='self-center' />
      </div>
    </button>
  )
}
