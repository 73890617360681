import { CdnAddress } from 'api/ApiConstants'
import React from 'react'

const Preloader = () => {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        position: 'fixed'
      }}
    >
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden'
        }}
      >
        <div className="jump">
          <img
            src={`${CdnAddress}/static/assets/imgs/logos/novaads-logo-text-vertical.svg`}
            alt="NovaAds"
            style={{
              height: '7rem'
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Preloader
