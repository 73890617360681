import { CdnAddress } from 'api/ApiConstants'
import LoginMenu from 'components/authorization/LoginMenu'
import InfoEmail from 'components/contact/InfoEmail'
import EnvironmentVariables from 'EnvironmentVariables'
import React from 'react'
import { NavLink } from 'react-router-dom'
import ApplicationRoutes from 'routes/ApplicationRoutes'

interface Props {
  hiddenClass?: string
  handleRemove: () => void
}

export default function MobileMenu({ hiddenClass, handleRemove }: Props) {
  return (
    <div
      className={`${hiddenClass} navbar-menu relative z-50 transition duration-300`}
    >
      <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"></div>
      <nav className="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto transition duration-300">
        <div className="flex items-center mb-8">
          <NavLink
            to={ApplicationRoutes.Home}
            className="mr-auto text-3xl font-semibold leading-none"
          >
            <img
              className="h-12"
              src={`${CdnAddress}/static/assets/imgs/logos/novaads-logo-text.svg`}
              alt="NovaAds"
            />
          </NavLink>
          <button className="navbar-close" onClick={handleRemove}>
            <svg
              className="h-6 w-6 text-gray-500 cursor-pointer hover:text-primary-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
        <div>
          <ul className="mobile-menu">
            {EnvironmentVariables.IsHomeEnvironmentEnabled && (
              <li className="mb-1 rounded-xl">
                <NavLink
                  to={ApplicationRoutes.Home}
                  className="block p-4 text-sm text-gray-500 hover:bg-primary-50 hover:text-primary-500 rounded-xl"
                >
                  Home
                </NavLink>
              </li>
            )}

            <li className="mb-1">
              <NavLink
                to={ApplicationRoutes.Services}
                className="block p-4 text-sm text-gray-500 hover:bg-primary-50 hover:text-primary-500"
              >
                Servizi
              </NavLink>
            </li>
            <li className="mb-1">
              <NavLink
                to={ApplicationRoutes.Pricing}
                className="block p-4 text-sm text-gray-500 hover:bg-primary-50 hover:text-primary-500"
              >
                Prezzi
              </NavLink>
            </li>
            <li className="mb-1">
              <NavLink
                to={ApplicationRoutes.ContactUs}
                className="block p-4 text-sm text-gray-500 hover:bg-primary-50 hover:text-primary-500"
              >
                Contatti
              </NavLink>
            </li>
          </ul>
          <div className="mt-4 pt-6 border-t border-gray-100">
            <LoginMenu
              signupClassName="block px-4 py-3 mb-3 text-center leading-none bg-primary-500 hover:bg-primary-500 text-white rounded no-underline"
              loginClassName="block px-4 py-3 mb-2 text-center leading-none border border-primary-200 hover:border-primary-300 rounded no-underline"
              logoutClassName="block px-4 py-3 mb-2 text-center leading-none border border-primary-200 hover:border-primary-300 rounded w-full no-underline"
            />
          </div>
        </div>
        <div className="mt-auto">
          <p className="my-4 text-xs text-gray-500">
            <NavLink
              to={ApplicationRoutes.ContactUs}
              className="text-gray-500 hover:text-primary-500"
            >
              Contatti
            </NavLink>
            <br />
            <InfoEmail />
          </p>
          {/* <a className="inline-block px-1" href="https://facebook.com">
            <img
              src={`${CdnAddress}/static/assets/imgs/icons/facebook-blue.svg"
              alt="NovaAds"
            />
          </a>
          <a className="inline-block px-1" href="https://twitter.com">
            <img
              src={`${CdnAddress}/static/assets/imgs/icons/twitter-blue.svg"
              alt="NovaAds"
            />
          </a>
          <a className="inline-block px-1" href="https://www.instagram.com">
            <img
              src={`${CdnAddress}/static/assets/imgs/icons/instagram-blue.svg"
              alt="NovaAds"
            />
          </a> */}
        </div>
      </nav>
    </div>
  )
}

// <li
// className={
// isActive.key == "1"
// ? "mb-1 menu-item-has-children rounded-xl active"
// : "mb-1 menu-item-has-children rounded-xl"
// }
// onClick={() => handleToggle("1")}
// >
// <span className="menu-expand">+</span>
// <NavLink
// to={ApplicationRoutes.Home}
// className="block p-4 text-sm text-gray-500 hover:bg-primary-50 hover:text-primary-500 rounded-xl"
// >
// Home
// </NavLink>
// </li>
// <ul
// className={isActive.key == "1" ? "dropdown pl-5" : "hidden"}
// >
// <li>
//   <NavLink to="/index">
//     <a className="block p-3 text-sm text-gray-500 hover:bg-primary-50 hover:text-primary-500">
//       Home 1
//     </a>
//   </NavLink>
// </li>
// <li>
//   <NavLink to="/index-2">
//     <a className="block p-3 text-sm text-gray-500 hover:bg-primary-50 hover:text-primary-500">
//       Home 2
//     </a>
//   </NavLink>
// </li>
// <li>
//   <NavLink to="/index-3">
//     <a className="block p-3 text-sm text-gray-500 hover:bg-primary-50 hover:text-primary-500">
//       Home 3
//     </a>
//   </NavLink>
// </li>
// <li>
//   <NavLink to="/index-4">
//     <a className="block p-3 text-sm text-gray-500 hover:bg-primary-50 hover:text-primary-500">
//       Home 4
//     </a>
//   </NavLink>
// </li>
// <li>
//   <NavLink to="/index-5">
//     <a className="block p-3 text-sm text-gray-500 hover:bg-primary-50 hover:text-primary-500">
//       Home 5
//     </a>
//   </NavLink>
// </li>
// </ul>
