import React, { useCallback, useEffect, useState } from 'react'
import ManagementRoutes from 'routes/ManagementRoutes'
import SidebarItem from './SidebarItem'
import MenuIcon from '@mui/icons-material/Menu'
import DevicesIcon from '@mui/icons-material/Devices'
import StorefrontIcon from '@mui/icons-material/Storefront'
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import CloseIcon from '@mui/icons-material/Close'
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice'
import SellIcon from '@mui/icons-material/Sell'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Link, useLocation } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import ApplicationRoutes from 'routes/ApplicationRoutes'
import GroupsIcon from '@mui/icons-material/Groups'
import OrganizationButton from 'components/buttons/OrganizationButton'
import SelectOrganization from 'components/popups/SelectOrganization'
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import PaymentsIcon from '@mui/icons-material/Payments'
import { CdnAddress } from 'api/ApiConstants'

export default function ManagementSidebar() {
  const location = useLocation()

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [organizationEnvironmentActive, setOrganizationEnvironmentActive] =
    useState<boolean>(false)
  const [userEnvironmentActive, setUserEnvironmentActive] =
    useState<boolean>(false)

  const [isChangeOrganization, setIsChangeOrganization] =
    useState<boolean>(false)

  const onToggle = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  useEffect(() => {
    setOrganizationEnvironmentActive(
      location.pathname.startsWith(ManagementRoutes.Organization.BaseRoot)
    )
    setUserEnvironmentActive(
      location.pathname.startsWith(ManagementRoutes.User.BaseRoot)
    )
  }, [location])

  const RenderMenuItems = useCallback(() => {
    if (organizationEnvironmentActive) {
      return (
        <OrganizationItems
          isMenuOpen={isMenuOpen}
          organizationEnvironmentActive={organizationEnvironmentActive}
        />
      )
    } else if (userEnvironmentActive) {
      return (
        <UserItems
          isMenuOpen={isMenuOpen}
          userEnvironmentActive={userEnvironmentActive}
        />
      )
    } else {
      return <GlobalItems isMenuOpen={isMenuOpen} />
    }
  }, [organizationEnvironmentActive, userEnvironmentActive, isMenuOpen])

  const onChangeOrganization = () => {
    setIsChangeOrganization(true)
  }

  return (
    <>
      <nav
        className={`bg-primary-700 ${isMenuOpen ? 'w-72' : 'w-24'}
        xl:w-72 wow animate__animated animate__fadeIn transition-all hidden sm:block z-10 h-full`}
      >
        <div className="flex flex-col h-full justify-between">
          <div className="flex w-full flex-col ">
            {/* Toggler */}
            <button
              className={`p-4 h-full wow animate__animated animate__fadeIn cursor-pointer text-white xl:hidden ${
                isMenuOpen ? 'text-right' : 'text-center'
              }`}
              type="button"
              onClick={() => {
                onToggle()
              }}
            >
              {isMenuOpen ? (
                <>
                  <CloseIcon />
                </>
              ) : (
                <>
                  <MenuIcon />
                </>
              )}
            </button>
            <div>{RenderMenuItems()}</div>
          </div>
         
          <OrganizationButton
            isMenuOpen={isMenuOpen}
            onClick={onChangeOrganization}
          />

        </div>
      </nav>
      {isChangeOrganization && (
        <SelectOrganization
          onClose={() => {
            setIsChangeOrganization(false)
          }}
          onOrganizationChanged={() => {
            setIsChangeOrganization(false)
          }}
        />
      )}
    </>
  )
}

interface IOrganizationItemsProps {
  isMenuOpen: boolean
  organizationEnvironmentActive: boolean
}

function OrganizationItems({
  isMenuOpen,
  organizationEnvironmentActive
}: IOrganizationItemsProps) {
  return (
    <ul
      className={`md:flex-col md:min-w-full h-full flex flex-col list-none`}
    >

      <SidebarItem
        to={ManagementRoutes.Shopwindows}
        content="torna indietro"
        isMenuOpen={isMenuOpen}
        icon={<ArrowBackIcon />}
      />

      <div className="flex-grow border-t border-gray-200 my-2"></div>

      <h3 className="my-2 font-semibold text-sm text-white hidden xl:block uppercase text-center">
        Impostazioni organizzazione
      </h3>

      <SidebarItem
        to={ManagementRoutes.Organization.Team}
        content="Membri"
        isMenuOpen={isMenuOpen}
        icon={<GroupsIcon />}
      />
    </ul>
  )
}

interface IUserItemsProps {
  isMenuOpen: boolean
  userEnvironmentActive: boolean
}

function UserItems({ isMenuOpen, userEnvironmentActive }: IUserItemsProps) {
  return (
    <ul
      className={`md:flex-col md:min-w-full h-full flex flex-col list-none`}
    >
      <SidebarItem
        to={ManagementRoutes.Shopwindows}
        content="torna indietro"
        isMenuOpen={isMenuOpen}
        icon={<ArrowBackIcon />}
      />

      <div className="flex-grow border-t border-gray-200 my-2"></div>

      <h3 className="my-2 font-semibold text-sm text-white hidden xl:block uppercase text-center">
        Impostazioni profilo
      </h3>

      <SidebarItem
        to={ManagementRoutes.User.Profile}
        content="Dettagli"
        isMenuOpen={isMenuOpen}
        icon={<AccountCircleOutlinedIcon />}
      />

      <SidebarItem
        to={ManagementRoutes.User.Invitations}
        content="Inviti"
        isMenuOpen={isMenuOpen}
        icon={<LocalPostOfficeIcon />}
      />

      <div className="flex-grow border-t border-gray-200 my-2"></div>

      <h3 className="my-2 font-semibold text-sm text-white hidden xl:block uppercase text-center">
        Gestione
      </h3>

      <SidebarItem
        to={ManagementRoutes.User.Organizations}
        content="Organizzazioni"
        isMenuOpen={isMenuOpen}
        icon={<GroupsIcon />}
      />

      <SidebarItem
        to={ManagementRoutes.User.Licenses}
        content="Licenze"
        isMenuOpen={isMenuOpen}
        icon={<SellIcon />}
      />

      <div className="flex-grow border-t border-gray-200 my-2"></div>

      <h3 className="my-2 font-semibold text-sm text-white hidden xl:block uppercase text-center">
        Negozio
      </h3>

      <SidebarItem
        to={ManagementRoutes.User.Orders}
        content="Ordini"
        isMenuOpen={isMenuOpen}
        icon={<LocalShippingIcon />}
      />

      <SidebarItem
        to={ManagementRoutes.User.Payments}
        content="Pagamenti"
        isMenuOpen={isMenuOpen}
        icon={<PaymentsIcon />}
      />
    </ul>
  )
}

interface IGlobalItemsProps {
  isMenuOpen: boolean
}

function GlobalItems({ isMenuOpen }: IGlobalItemsProps) {
  return (
    <ul
      className={`md:flex-col sm:min-w-full h-full flex flex-col list-none`}
    >
      <SidebarItem
        to={ManagementRoutes.Shopwindows}
        content="Vetrine"
        isMenuOpen={isMenuOpen}
        icon={<StorefrontIcon />}
      />
      <SidebarItem
        to={ManagementRoutes.Devices}
        content="Dispositivi"
        isMenuOpen={isMenuOpen}
        icon={<DevicesIcon />}
      />
      <SidebarItem
        to={ManagementRoutes.Playlists}
        content="Playlist"
        isMenuOpen={isMenuOpen}
        icon={<PlaylistPlayIcon />}
      />
      <SidebarItem
        to={ManagementRoutes.Medias}
        content="Contenuti"
        isMenuOpen={isMenuOpen}
        icon={<ImageOutlinedIcon />}
      />

      <SidebarItem
        to={ManagementRoutes.Organization.Team}
        content="Organizzazione"
        isMenuOpen={isMenuOpen}
        icon={<CorporateFareIcon />}
      />

      <SidebarItem
        to={ManagementRoutes.User.Profile}
        content="Profilo"
        isMenuOpen={isMenuOpen}
        icon={<AccountCircleOutlinedIcon />}
      />

      <SidebarItem
        to={ManagementRoutes.Store}
        content="Negozio"
        isMenuOpen={isMenuOpen}
        icon={<LocalGroceryStoreOutlinedIcon />}
      />
    </ul>
  )
}
