import React, { useCallback, useEffect, useState } from 'react'
import BottomNavigationBar from 'components/navigation-bars/BottomNavigationBar'
import BackToTop from 'components/scoll/BackToTop'
import ManagementSidebar from 'components/navigation-bars/ManagementSidebar'
import { FilterContext } from 'middlewares/FilterProvider'
import { createSearchParams, Outlet, useNavigate } from 'react-router-dom'
import { MediaContext } from 'middlewares/MediaContext'
import domainService from 'api/services/DomainService'
import Preloader from 'components/loaders/Preloader'
import { Helmet } from 'react-helmet'
import ApplicationRoutes from 'routes/ApplicationRoutes'
import { AxiosError } from 'axios'
import authService from 'api/services/AuthorizeService'
import { ToastContainer } from 'react-toastify'
import IOrganization from 'interfaces/organizations/IOrganization'
import { LicenseContext } from 'middlewares/LicenseContext'
import { DeviceContext } from 'middlewares/DevicesContext'
import SelectionMode from 'middlewares/SelectionMode'
import { ProfileContextProvider } from 'middlewares/ProfileContext'
import { PaymentContext } from 'middlewares/PaymentContext'
import Header from 'components/headers/Header'
import MobileMenu from 'components/menus/MobileMenu'

const ManagementLayout = () => {
  const navigate = useNavigate()

  const [currentOrganization, setCurrentOrganization] = useState<
    IOrganization | undefined
  >(undefined)

  const [reloading, setReloading] = useState(false)

  const signOutAndRedirectToErrorPage = useCallback(
    async (error: string, errorMessage: string) => {
      const isauthenticated = await authService.isAuthenticated()

      if (isauthenticated) {
        try {
          await authService.signOut()
        } catch (error) {
          //Do nothing
        }
        navigate(
          `${ApplicationRoutes.Error}?error=${error}&description=${errorMessage}`
        )
      }

      navigate({
        pathname: ApplicationRoutes.Error,
        search: `${createSearchParams({
          error: error,
          description: errorMessage
        })}`
      })
    },
    [navigate]
  )

  const onError = useCallback(
    (error: AxiosError) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        switch (error.response.status) {
          // Forbidden
          case 401:
            signOutAndRedirectToErrorPage(
              '401',
              'Non sei authorizzato ad operare in questa organizzazione!'
            )
            break
          case 404:
            // Not found
            signOutAndRedirectToErrorPage(
              '404',
              'Impossibile trovare la tua organizzazione!'
            )
            break
          case 500:
            // Internal Server Error
            // In this case do not log out the user
            navigate({
              pathname: ApplicationRoutes.Error,
              search: `${createSearchParams({
                error: '500',
                description: 'Si è verificato un errore imprevisto!'
              })}`
            })

            break
          default:
            signOutAndRedirectToErrorPage(
              `${error.response.status}`,
              'Si è verificato un errore imprevisto!'
            )
            break
        }
      } else if (error.request) {
        // The request was made but no response was received, timeout
        navigate({
          pathname: ApplicationRoutes.Error,
          search: `${createSearchParams({
            error: '408',
            description:
              'La richiesta inviata dal client al server web ha richiesto più tempo di quanto consentito'
          })}`
        })
      } else {
        // Something happened in setting up the request that triggered an Error
        navigate({
          pathname: ApplicationRoutes.Error,
          search: `${createSearchParams({
            error: 'Errore di rete',
            description: 'Non sei connesso ad internet'
          })}`
        })
      }
    },
    [signOutAndRedirectToErrorPage, navigate]
  )

  useEffect(() => {
    const subscriptionId = domainService.subscribe(organizationChangedCallback)

    if (!!!currentOrganization) {
      const domainServiceOrganization = domainService.GetCurrentOrganization()

      if (domainServiceOrganization) {
        setCurrentOrganization(domainServiceOrganization)
      } else {
        domainService.retrieveOrganizations().catch(onError)
      }
    }

    return () => {
      domainService.unsubscribe(subscriptionId)
    }
  }, [onError, currentOrganization])

  const organizationChangedCallback = (
    currentOrganization: IOrganization | undefined,
    organizations: IOrganization[]
  ) => {
    setCurrentOrganization(currentOrganization)
    setReloading(true)
  }

  useEffect(() => {
    if (reloading) {
      const timer = setTimeout(() => {
        clearInterval(timer)
        setReloading(false)
      }, 1000)

      return () => {
        clearInterval(timer)
      }
    }
  }, [reloading])

  const [hiddenClass, setHiddenClass] = useState('hidden')

  const handleHidden = () => setHiddenClass('')

  const handleRemove = () => {
    if (hiddenClass === '') {
      setHiddenClass('hidden')
    }
  }

  return (
    <>
      {reloading || !!!currentOrganization ? (
        <Preloader />
      ) : (
        <>
          <PaymentContext>
            <FilterContext>
              <ProfileContextProvider>
                <DeviceContext selectionMode={SelectionMode.Single}>
                  <MediaContext>
                    <LicenseContext>
                      <>
                      <div className="main font-body text-body h-screen w-screen flex flex-col bg-primary-100">
                        <Header
                          handleHidden={handleHidden}
                          inDashboard={true}
                          />

                        <MobileMenu hiddenClass={hiddenClass} handleRemove={handleRemove} />

                        <div className='flex flex-row h-full w-full overflow-hidden'>
                          <ManagementSidebar />
                          <main role="main" className="flex flex-row h-full w-full overflow-auto">
                            <Outlet />
                          </main>
                        </div>

                        <BottomNavigationBar />
                      </div>
                      <BackToTop />
                        <ToastContainer
                          position="bottom-right"
                          autoClose={5000}
                          hideProgressBar={false}
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                        />
                        </>
                    </LicenseContext>
                  </MediaContext>
                </DeviceContext>
              </ProfileContextProvider>
            </FilterContext>
          </PaymentContext>
        </>
      )}
    </>
  )
}

export default ManagementLayout
