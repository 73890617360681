import React, { useCallback, useEffect, useState } from 'react'
import { animateScroll as scroll } from 'react-scroll'

export default function BackToTop() {
  const [visible, setVisible] = useState(false)

  const scrollChanged = useCallback(() => {
    const visibilityState = window.scrollY > 200
    if (visibilityState !== visible) {
      setVisible(visibilityState)
    }
  }, [visible])

  useEffect(() => {
    document.addEventListener('scroll', scrollChanged)
    return () => {
      document.removeEventListener('scroll', scrollChanged)
    }
  }, [scrollChanged])

  const scrollToTop = () => {
    scroll.scrollToTop()
  }

  return (
    <>
      {visible && (
        <button
          id="scrollUp"
          className="wow animation-shadow-pulse"
          onClick={scrollToTop}
        >
          <svg
            className="w-4 h-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 10l7-7m0 0l7 7m-7-7v18"
            ></path>
          </svg>
        </button>
      )}
    </>
  )
}
