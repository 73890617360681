import React, { useEffect } from 'react'
import Preloader from 'components/loaders/Preloader'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ApplicationRoutes from 'routes/ApplicationRoutes'
import AccountService from 'api/services/AccountService'
import PromiseUtility from 'utilities/PromiseUtility'

export default function ConfirmEmailChange() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    //Check that the required parameters exist
    if (
      searchParams.has('email') &&
      searchParams.has('code') &&
      searchParams.has('userId')
    ) {
      const email = searchParams.get('email')
      const code = searchParams.get('code')
      const userId = searchParams.get('userId')

      if (email && code && userId) {
        //Parameters exist and are valid, start the transaction
        const promise = AccountService.confirmEmailChange(email, code, userId)

        PromiseUtility.onErrorNavigate(promise, navigate).then(() => {
          navigate(ApplicationRoutes.Account.EmailChanged)
        })
      }
    }
  }, [navigate, searchParams])

  return <Preloader />
}
