import INotification from 'interfaces/account/INotification'
import { useEffect, useState } from 'react'
import UserService from 'api/services/UserService'
import NotificationsIcon from '@mui/icons-material/Notifications'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

export default function Notifications() {
  
  const areNotificationLoaded : boolean = true;
  
  const [notifications, setNotifications] = useState<
    INotification[] | undefined
  >([])

  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false)

  useEffect(() => {
    UserService.getUserNotifications()
      .then(setNotifications)
      .catch((error) => {
        console.log(error.message)
      })
  }, [areNotificationLoaded])

  return (
    <div>
      <button onClick={() => setIsPanelOpen(!isPanelOpen)}>
        <NotificationsIcon className="text-primary-500 hover:text-primary-300 hover:cursor-pointer" />
      </button>
      {isPanelOpen && (
        <div className="relative">
          <div className="absolute right-0 bg-white rounded-md shadow-md overflow-hidden z-20 w-96">
            <div className="py-2">
              <a className="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2">
                <img
                  className="h-8 w-8 rounded-full object-cover mx-1"
                  src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80"
                  alt="avatar"
                />
                <p className="text-gray-600 text-sm mx-2">
                  <span className="font-bold">Sara Salah</span> replied on the{' '}
                  <span className="font-bold text-primary-500">Upload Image</span>{' '}
                  artical . 2m
                </p>
              </a>
              <a className="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2">
                <img
                  className="h-8 w-8 rounded-full object-cover mx-1"
                  src="https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
                  alt="avatar"
                />
                <p className="text-gray-600 text-sm mx-2">
                  <span className="font-bold">Slick Net</span> start following
                  you . 45m
                </p>
              </a>
              <a className="flex items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2">
                <img
                  className="h-8 w-8 rounded-full object-cover mx-1"
                  src="https://images.unsplash.com/photo-1450297350677-623de575f31c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80"
                  alt="avatar"
                />
                <p className="text-gray-600 text-sm mx-2">
                  <span className="font-bold">Jane Doe</span> Like Your reply on{' '}
                  <span className="font-bold text-primary-500">Test with TDD</span>{' '}
                  artical . 1h
                </p>
              </a>
              <a className="flex items-center px-4 py-3 hover:bg-gray-100 -mx-2">
                <img
                  className="h-8 w-8 rounded-full object-cover mx-1"
                  src="https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=398&q=80"
                  alt="avatar"
                />
                <p className="text-gray-600 text-sm mx-2">
                  <span className="font-bold">Abigail Bennett</span> start
                  following you . 3h
                </p>
              </a>
            </div>
            <button className="block bg-primary-800 hover:bg-primary-500 text-white text-center w-full font-bold py-2">
              <MarkEmailReadIcon /> Segna tutte come lette
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
