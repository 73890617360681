import { useState } from 'react'
import SidebarItem from './../SidebarItem'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import { NavLink } from 'react-router-dom'
import ApplicationRoutes from 'routes/ApplicationRoutes'
import TranslateIcon from '@mui/icons-material/Translate'
import AdministrationRoutes from 'routes/AdministrationRoutes'
import { CdnAddress } from 'api/ApiConstants'

export default function AdminSidebar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const onToggle = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <>
      <nav
        className={`bg-white left-0 fixed top-0 ${isMenuOpen ? 'w-72' : 'w-32'}
        xl:w-72 wow animate__animated animate__fadeIn transition-all hidden sm:block z-10`}
      >
        <div className="flex flex-row md:flex-col justify-between md:min-h-screen z-10 py-4 px-6">
          <div className="flex w-full flex-col justify-between">
            {/* Toggler */}
            <button
              className={`wow animate__animated animate__fadeIn cursor-pointer text-black opacity-50 px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent block xl:hidden ${
                isMenuOpen ? 'text-right' : 'text-center'
              }`}
              type="button"
              onClick={() => {
                onToggle()
              }}
            >
              {isMenuOpen ? (
                <>
                  <CloseIcon />
                </>
              ) : (
                <>
                  <MenuIcon />
                </>
              )}
            </button>
            {/* Brand */}

            <div className="text-3xl font-semibold leading-none hidden xl:block items-center text-center content-center self-center wow animate__animated animate__fadeIn">
              <NavLink to={ApplicationRoutes.Home}>
                <img
                  className="h-20"
                  src={`${CdnAddress}/static/assets/imgs/logos/novaads-logo.svg`}
                  alt="Logo"
                />
              </NavLink>
            </div>

            <div>
              <ul
                className={`md:flex-col md:min-w-full h-full flex flex-col list-none mt-4`}
              >
                <SidebarItem
                  to={AdministrationRoutes.Terms}
                  content="Termini"
                  isMenuOpen={isMenuOpen}
                  icon={<TranslateIcon />}
                />
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}
