import React from 'react'
import { NavLink } from 'react-router-dom'
import ApplicationRoutes from 'routes/ApplicationRoutes'
import InfoEmail from 'components/contact/InfoEmail'
import { CdnAddress } from 'api/ApiConstants'

const Footer = () => {
  return (
    <>
      <footer className='pt-10 pb-4'>
        <div
          className="container px-4 wow animate__animated animate__fadeIn"
          data-wow-delay=".3s"
        >
          <div className="flex flex-wrap mb-12 lg:mb-20 -mx-3 text-center lg:text-left">
            <div className="w-full lg:w-1/5 px-3 mb-6 lg:mb-0">
              <NavLink
                to={ApplicationRoutes.Home}
                className="inline-block mx-auto lg:mx-0 text-3xl font-semibold leading-none"
              >
                <img
                  className="h-36"
                  src={`${CdnAddress}/static/assets/imgs/logos/novaads-logo-text-vertical.svg`}
                  alt="NovaAds"
                />
              </NavLink>
            </div>
            <div className="w-full lg:w-2/5 px-3 mb-8 lg:mb-0">
              <p className="max-w-md mx-auto lg:max-w-full lg:mx-0 lg:pr-32 lg:text-lg text-gray-500 leading-relaxed">
                Aiutiamo a <strong>massimizzare</strong> i tuoi contenuti
                digitali
              </p>
            </div>
            <div className="w-full lg:w-1/5 px-3 mb-8 lg:mb-0">
              <p className="mb-2 lg:mb-4 lg:text-lg font-bold font-heading text-gray-800">
                Ufficio
              </p>
              <p className="lg:text-lg">
                <a href="https://maps.app.goo.gl/s3eLjdgVfSBMkD5J6">Via Francesco Petrarca 19a, 50063, Figline Valdarno, Firenze, Italia</a>
              </p>
            </div>
            <div className="w-full lg:w-1/5 px-3">
              <p className="mb-2 lg:mb-4 lg:text-lg font-bold font-heading text-gray-800">
                Contatti
              </p>
              <p className="lg:text-lg">
                <a
                  href="tel:+393913307552"
                >
                  (+39) 391-3307552
                </a>
              </p>
              <p className="lg:text-lg">
                <InfoEmail />
              </p>
            </div>
          </div>
          <div className="container flex flex-col lg:flex-row align-items: center; items-center lg:justify-center">
            
              <p className="text-sm text-gray-500">
                &copy;NovaAds 2022. Tutti i diritti riservati.
              </p>
              <NavLink
                to={ApplicationRoutes.PrivacyAndPolices}
                className="text-sm text-gray-500 ml-2 hover:text-primary-500 transition-colors"
              >
                Privacy & Policies
              </NavLink>
              <NavLink
                to={ApplicationRoutes.TermsOfUse}
                className="text-sm text-gray-500 ml-2 hover:text-primary-500 transition-colors"
              >
                Termini di utilizzo
              </NavLink>
            
            {/*<div className="order-first lg:order-last -mx-2 mb-4 lg:mb-0">*/}
            {/*  <a className="inline-block px-2" href="https://facebook.com">*/}
            {/*    <img*/}
            {/*      src={`${CdnAddress}/static/assets/imgs/icons/facebook-blue.svg"*/}
            {/*      alt="NovaAds"*/}
            {/*    />*/}
            {/*  </a>*/}
            {/*  <a className="inline-block px-2" href="https://twitter.com">*/}
            {/*    <img*/}
            {/*      src={`${CdnAddress}/static/assets/imgs/icons/twitter-blue.svg"*/}
            {/*      alt="NovaAds"*/}
            {/*    />*/}
            {/*  </a>*/}
            {/*  <a className="inline-block px-2" href="https://www.instagram.com">*/}
            {/*    <img*/}
            {/*      src={`${CdnAddress}/static/assets/imgs/icons/instagram-blue.svg"*/}
            {/*      alt="NovaAds"*/}
            {/*    />*/}
            {/*  </a>*/}
            {/*</div>*/}
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
