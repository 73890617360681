import React, { ReactElement, Suspense, lazy, useMemo } from 'react'

// Libraries
import WOW from 'wowjs'
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom'

// Styles
import 'animate.css'
import 'assets/css/tailwind.css'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'react-toastify/dist/ReactToastify.css'

// Routes
import ManagementRoutes from 'routes/ManagementRoutes'
import ApplicationRoutes from 'routes/ApplicationRoutes'

// Components
import Preloader from 'components/loaders/Preloader'
import ProtectedRoute from 'components/authorization/ProtectedRoute'

// Layouts
import Layout from 'layouts/Layout'
import ManagementLayout from 'layouts/ManagementLayout'
import EnvironmentVariables from 'EnvironmentVariables'
import ConfirmEmailChange from 'pages/account/ConfirmEmailChange'
import AdministrationRoute from 'components/authorization/AdministrationRoute'
import AdministrationRoutes from 'routes/AdministrationRoutes'
import AdministrationLayout from 'layouts/AdministrationLayout'
import { GlobalizationContextProvider } from 'middlewares/GlobalizationContext'

// Pages
const Home = lazy(async () => await import('pages/Home'))
const AboutUs = lazy(async () => await import('pages/AboutUs'))
const Services = lazy(async () => await import('pages/Services'))
const Pricing = lazy(async () => await import('pages/Pricing'))
const ContactUs = lazy(async () => await import('pages/ContactUs'))
const TermsOfUse = lazy(async () => await import('pages/TermsOfUse'))
const PrivacyAndPolices = lazy(
  async () => await import('pages/PrivacyAndPolicies')
)
const NewsletterSubscriptionRequested = lazy(
  async () => await import('pages/NewsletterSubscriptionRequested')
)
const UsageAgreement = lazy(async () => await import('pages/UsageAgreement'))
const Landing = lazy(async () => await import('pages/Landing'))
const Error = lazy(async () => await import('pages/Error'))
const AccessDenied = lazy(async () => await import('pages/AccessDenied'))
const SignIn = lazy(async () => await import('pages/account/SignIn'))
const Signup = lazy(async () => await import('pages/account/Signup'))
const AccountConfirmed = lazy(
  async () => await import('pages/account/AccountConfirmed')
)
const EmailChanged = lazy(
  async () => await import('pages/account/EmailChanged')
)
const RequestProcessed = lazy(
  async () => await import('pages/account/RequestProcessed')
)
const ConfirmAccount = lazy(
  async () => await import('pages/account/ConfirmAccount')
)
const ResetPassword = lazy(
  async () => await import('pages/account/ResetPassword')
)
const PasswordResetSuccessfully = lazy(
  async () => await import('pages/account/PasswordResetSuccessfully')
)
const ShopWindows = lazy(
  async () => await import('pages/management/ShopWindows')
)
const Devices = lazy(async () => await import('pages/management/Devices'))
const Playlists = lazy(async () => await import('pages/management/Playlists'))
const Medias = lazy(async () => await import('pages/management/Medias'))
const Store = lazy(async () => await import('pages/management/Store'))

const Summary = lazy(async () => await import('pages/organization/Summary'))
const Team = lazy(async () => await import('pages/organization/Team'))

const Profile = lazy(async () => await import('pages/user/Profile'))
const Licenses = lazy(async () => await import('pages/user/Licenses'))
const Organizations = lazy(async () => await import('pages/user/Organizations'))
const Invitations = lazy(async () => await import('pages/user/Invitations'))
const Orders = lazy(async () => await import('pages/user/Orders'))
const Order = lazy(async () => await import('pages/user/Order'))
const Payments = lazy(async () => await import('pages/user/Payments'))
const Invoice = lazy(async () => await import('pages/user/Invoice'))

const Terms = lazy(async () => await import('pages/admin/Terms'))

const GlobalizedRoot = (
  path: string,
  element: ReactElement
): React.ReactFragment => {
  return [path, `:culture${path}`].map((value) => {
    return <Route key={value} path={value} element={element} />
  })
}

const GlobalizedTree = (
  path: string,
  elements: React.ReactElement[]
): React.ReactFragment => {
  return [path, `:culture${path}`].map((value) => {
    return (
      <Route key={value} path={value}>
        {elements.map((value, index) => {
          return React.cloneElement(value, { key: index }, null)
        })}
      </Route>
    )
  })
}

export default function App() {
  new WOW.WOW({
    live: false
  }).init()

  const memoizedLoader = useMemo(() => <Preloader />, [])

  return (
    <Suspense fallback={memoizedLoader}>
      <BrowserRouter>
        <Routes>
          <Route
            element={
              <GlobalizationContextProvider>
                <Outlet />
              </GlobalizationContextProvider>
            }
          >
            {/* Error pages */}
            <Route element={<Layout />}>
              <Route
                path="*"
                element={
                  <Error
                    error="404"
                    description="Ci dispiace, la pagina da te richiesta non è stata trovata."
                  />
                }
              />

              {GlobalizedRoot(ApplicationRoutes.Error, <Error />)}
              {GlobalizedRoot(ApplicationRoutes.AccessDenied, <AccessDenied />)}

              {/* Application routes */}
              {GlobalizedRoot(ApplicationRoutes.Landing, <Landing />)}

              {EnvironmentVariables.IsHomeEnvironmentEnabled && (
                <>{GlobalizedRoot(ApplicationRoutes.Home, <Home />)}</>
              )}

              {EnvironmentVariables.IsAboutUsEnvironmentEnabled && (
                <>{GlobalizedRoot(ApplicationRoutes.AboutUs, <AboutUs />)}</>
              )}

              {EnvironmentVariables.IsServicesEnvironmentEnabled && (
                <>{GlobalizedRoot(ApplicationRoutes.Services, <Services />)}</>
              )}

              {EnvironmentVariables.IsPricingEnvironmentEnabled && (
                <>{GlobalizedRoot(ApplicationRoutes.Pricing, <Pricing />)}</>
              )}

              {EnvironmentVariables.IsContactUsEnvironmentEnabled && (
                <>
                  {GlobalizedRoot(ApplicationRoutes.ContactUs, <ContactUs />)}
                </>
              )}
              {EnvironmentVariables.IsTermsOfUseEnvironmentEnabled && (
                <>
                  {GlobalizedRoot(ApplicationRoutes.TermsOfUse, <TermsOfUse />)}
                </>
              )}
              {EnvironmentVariables.IsPrivacyAndPolicesEnvironmentEnabled && (
                <>
                  {GlobalizedRoot(
                    ApplicationRoutes.PrivacyAndPolices,
                    <PrivacyAndPolices />
                  )}
                </>
              )}

              {GlobalizedRoot(
                ApplicationRoutes.NewsletterSubscriptionRequested,
                <NewsletterSubscriptionRequested />
              )}

              {EnvironmentVariables.IsUsageAgreementEnvironmentEnabled && (
                <>
                  {GlobalizedRoot(
                    ApplicationRoutes.UsageAgreement,
                    <UsageAgreement />
                  )}
                </>
              )}

              {/* Authentication Routes */}
              <Route>
                {EnvironmentVariables.IsUserAllowedToRegister && (
                  <>
                    {GlobalizedRoot(
                      ApplicationRoutes.Account.Register,
                      <Signup />
                    )}
                  </>
                )}

                {GlobalizedRoot(ApplicationRoutes.Account.SignIn, <SignIn />)}

                {GlobalizedRoot(
                  ApplicationRoutes.Account.AccountConfirmed,
                  <AccountConfirmed />
                )}

                {GlobalizedRoot(
                  ApplicationRoutes.Account.EmailChanged,
                  <EmailChanged />
                )}

                {GlobalizedRoot(
                  ApplicationRoutes.Account.AccountProcessed,
                  <RequestProcessed />
                )}

                {GlobalizedRoot(
                  ApplicationRoutes.Account.ConfirmAccount,
                  <ConfirmAccount />
                )}

                {GlobalizedRoot(
                  ApplicationRoutes.Account.ResetPassword,
                  <ResetPassword />
                )}

                {GlobalizedRoot(
                  ApplicationRoutes.Account.PasswordResetted,
                  <PasswordResetSuccessfully />
                )}

                {GlobalizedRoot(
                  ApplicationRoutes.Account.ConfirmEmailChange,
                  <ConfirmEmailChange />
                )}
              </Route>
            </Route>

            {/* Dashboard Routes */}
            <Route
              element={
                <ProtectedRoute>
                  <ManagementLayout />
                </ProtectedRoute>
              }
            >
              {GlobalizedRoot(ManagementRoutes.Shopwindows, <ShopWindows />)}

              {GlobalizedRoot(ManagementRoutes.Devices, <Devices />)}

              {GlobalizedRoot(ManagementRoutes.Playlists, <Playlists />)}

              {GlobalizedRoot(ManagementRoutes.Medias, <Medias />)}

              {GlobalizedRoot(ManagementRoutes.Store, <Store />)}

              {GlobalizedRoot(
                ManagementRoutes.Organization.Summary,
                <Summary />
              )}

              {GlobalizedRoot(ManagementRoutes.Organization.Team, <Team />)}

              {GlobalizedRoot(ManagementRoutes.User.Profile, <Profile />)}

              {GlobalizedRoot(ManagementRoutes.User.Licenses, <Licenses />)}

              {GlobalizedRoot(
                ManagementRoutes.User.Organizations,
                <Organizations />
              )}

              {GlobalizedRoot(
                ManagementRoutes.User.Invitations,
                <Invitations />
              )}

              {GlobalizedTree(ManagementRoutes.User.Orders, [
                <Route path="" element={<Orders />}></Route>,
                <Route path=":orderId" element={<Order />}></Route>
              ])}

              {GlobalizedTree(ManagementRoutes.User.Payments, [
                <Route path="" element={<Payments />}></Route>,
                <Route path=":paymentId/invoice" element={<Invoice />}></Route>
              ])}
            </Route>

            {/* Dashboard Routes */}
            <Route
              element={
                <AdministrationRoute>
                  <AdministrationLayout />
                </AdministrationRoute>
              }
            >
              {GlobalizedRoot(AdministrationRoutes.Terms, <Terms />)}
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  )
}
