import { AxiosResponse } from 'axios'
import { ApplicationAPI } from '../ApiConstants'
import IDevice from 'interfaces/devices/IDevice'
import RequestService from './RequestService'
import IDeviceRegistration from 'interfaces/devices/IDeviceRegistration'
import IDeviceInputModel from 'interfaces/devices/IDeviceInputModel'
import IDeviceConfiguration from 'interfaces/devices/IDeviceConfiguration'

export default class DeviceService {
  static async getDevices<R = IDevice[]>(): Promise<R> {
    return await RequestService.Get(ApplicationAPI.Devices.Base)
  }

  static async getDevice<R = IDevice>(id: string): Promise<R> {
    return await RequestService.Get(ApplicationAPI.Devices.Base, id)
  }

  static async deleteDevice<T = IDevice, R = AxiosResponse<T>>(
    id: string
  ): Promise<R> {
    return await RequestService.Delete(ApplicationAPI.Devices.Base, id)
  }

  static async registerDevice<R = IDevice>(
    data: IDeviceRegistration
  ): Promise<R> {
    return await RequestService.Post(ApplicationAPI.Devices.Base, data)
  }

  static async updateDevice<R = boolean>(data: IDeviceInputModel): Promise<R> {
    return await RequestService.Put(ApplicationAPI.Devices.Base, data)
  }

  static async getDeviceConfiguration<R = IDeviceConfiguration>(
    id: string
  ): Promise<R> {
    return await RequestService.Get(
      `${ApplicationAPI.Devices.Configuration}/${id}`
    )
  }

  static async updateDeviceConfiguration(
    data: IDeviceConfiguration,
    id: string
  ): Promise<void> {
    return await RequestService.Post(
      `${ApplicationAPI.Devices.Configuration}/${id}`,
      data
    )
  }

  static async isRegistrationCodeValid(
    registrationCode: string
  ): Promise<void> {
    return await RequestService.Get(
      `${ApplicationAPI.Devices.Base}/verification/registration-code/${registrationCode}`
    )
  }
}
