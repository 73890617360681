import LicenseService from 'api/services/LicenseService'
import ILicense from 'interfaces/licensing/ILicense'
import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useMemo,
  useReducer
} from 'react'
import SelectionMode from './SelectionMode'

enum Action {
  SetCurrentLicense = 'SET_CURRENT_LICENSE',
  AddSelectedLicense = 'ADD_SELECTED_LICENSE',
  RemoveSelectedLicense = 'REMOVE_SELECTED_LICENSE',
  SetSelectionMode = 'SET_SELECTION_MODE',
  Reset = 'RESET',
  SetLicenses = 'SET_LICENSES'
}

interface ILicenseContextProps {
  children?: ReactElement
  selectionMode?: SelectionMode
  selectedLicenses?: ILicense[]
}

interface ILicenseContext {
  Licenses: ILicense[]
  /** The License currently displayed or selected as active */
  selectedLicense: ILicense | undefined | null
  /** Selected Licenses. */
  selectedLicenses: ILicense[]
  /** Get the selection mode, if the mode is single, it will be possible to select only one License, otherwise it will be possible to select multiple License. */
  selectionMode: SelectionMode | undefined
  /** Set the selection mode, if the mode is single, it will be possible to select only one License, otherwise it will be possible to select multiple License. */
  setSelectionMode: (value: SelectionMode) => any
  selectLicense: (value: ILicense) => any
  unselectLicense: (value: ILicense) => any
  isSelected: (value: ILicense) => boolean
}

interface ILicenseContextUpdater {
  fetchLicenses: () => Promise<ILicense[]>
}

interface ILicenseContextState {
  Licenses: ILicense[]
  selectedLicense: ILicense | undefined | null
  selectedLicenses: ILicense[]
  selectionMode: SelectionMode | undefined
}

interface ILicenseContextAction {
  type: Action
  selectedLicense?: ILicense
  selectionMode?: SelectionMode
  LicenseToAdd?: ILicense
  LicenseToRemove?: ILicense
  Licenses?: ILicense[]
}

const Context = createContext<ILicenseContext | undefined>(undefined)

const ContextUpdater = createContext<ILicenseContextUpdater | undefined>(
  undefined
)

function init(initialArgs: ILicenseContextProps): ILicenseContextState {
  var selectionMode = SelectionMode.Single
  if (initialArgs.selectionMode) {
    selectionMode = initialArgs.selectionMode
  }

  var selectedLicenses = new Array<ILicense>()
  if (initialArgs.selectedLicenses) {
    selectedLicenses = Array.from(initialArgs.selectedLicenses)
  }

  return {
    Licenses: new Array<ILicense>(),
    selectedLicense: undefined,
    selectedLicenses: selectedLicenses,
    selectionMode: selectionMode
  }
}

function reducer(
  state: ILicenseContextState,
  action: ILicenseContextAction
): ILicenseContextState {
  switch (action.type) {
    case Action.AddSelectedLicense:
      // if LicenseToAdd is invalid return state to avoid refresh
      if (!action.LicenseToAdd) return state

      if (
        (state.selectionMode as SelectionMode) ===
        (SelectionMode.Multiple as SelectionMode)
      ) {
        // If License doesnt exists then add the License
        if (
          state.selectedLicenses.findIndex((value) => {
            return action.LicenseToAdd?.id === value.id
          }) < 0
        ) {
          state.selectedLicenses = Array.from(state.selectedLicenses)
          state.selectedLicenses.push(action.LicenseToAdd)
          state.selectedLicense = undefined
        }
      } else {
        state.selectedLicenses = []
        state.selectedLicenses.push(action.LicenseToAdd)
        state.selectedLicense = action.LicenseToAdd
      }

      return {
        ...state
      }
    case Action.RemoveSelectedLicense:
      // if LicenseToRemove is invalid return state to avoid refresh
      if (!action.LicenseToRemove) return state

      // If License is included then remove the License
      var index = state.selectedLicenses.findIndex((value) => {
        return action.LicenseToRemove?.id === value.id
      })

      if (index < 0) {
        // The License is not present in the collection
        return state
      }

      // Remove the License
      state.selectedLicenses.splice(index, 1)
      state.selectedLicenses = Array.from(state.selectedLicenses)
      state.selectedLicense = undefined

      return {
        ...state
      }
    case Action.SetSelectionMode:
      return {
        ...state,
        selectionMode: action.selectionMode
      }

    case Action.SetLicenses:
      if (!action.Licenses) {
        return state
      }

      return {
        ...state,
        Licenses: action.Licenses,
        selectedLicenses: [],
        selectedLicense: undefined
      }
    default:
      throw new Error()
  }
}

export const LicenseContext = (props: ILicenseContextProps) => {
  const [state, dispatch] = useReducer(reducer, props, init)

  const context = useMemo(() => {
    return {
      Licenses: state.Licenses,
      selectedLicense: state.selectedLicense,
      selectedLicenses: state.selectedLicenses,
      selectionMode: state.selectionMode,
      setSelectionMode(value: SelectionMode) {
        dispatch({ type: Action.SetSelectionMode, selectionMode: value })
      },
      selectLicense: (value: ILicense) => {
        dispatch({ type: Action.AddSelectedLicense, LicenseToAdd: value })
      },
      unselectLicense: (value: ILicense) => {
        dispatch({ type: Action.RemoveSelectedLicense, LicenseToRemove: value })
      },
      isSelected: (value: ILicense) => {
        const result =
          state.selectedLicenses.findIndex((selectedLicense) => {
            return selectedLicense.id === value.id
          }) >= 0
        return result
      },
      fetchLicenses: async () => {
        const Licenses = await LicenseService.getLicenses()
        dispatch({ type: Action.SetLicenses, Licenses: Licenses })
        return Licenses
      }
    }
  }, [
    state.selectedLicense,
    state.selectedLicenses,
    state.Licenses,
    state.selectionMode
  ])

  const updater = useMemo(() => {
    return {
      fetchLicenses: async () => {
        const Licenses = await LicenseService.getLicenses()
        dispatch({ type: Action.SetLicenses, Licenses: Licenses })
        return Licenses
      }
    }
  }, [])

  return (
    <Context.Provider value={context}>
      <ContextUpdater.Provider value={updater}>
        {props.children}
      </ContextUpdater.Provider>
    </Context.Provider>
  )
}

export const useLicenses = () => {
  const LicenseContext = useContext(Context)
  return LicenseContext
}

export const useLicensesUpdater = () => {
  const LicenseContextUpdater = useContext(ContextUpdater)
  return LicenseContextUpdater
}

interface LicenseConsumerProps {
  children: (value: ILicenseContext | undefined) => ReactNode
}

export const LicenseConsumer = (props: LicenseConsumerProps) => {
  return <Context.Consumer>{props.children}</Context.Consumer>
}
