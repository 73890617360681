import { ApplicationAPI } from '../ApiConstants'
import RequestService from './RequestService'
import { IOrganizationUser } from 'interfaces/organizations/IOrganizationUser'
import IOrganization from 'interfaces/organizations/IOrganization'
import IOrganizationInput from 'interfaces/organizations/IOrganizationInput'
import { AxiosResponse } from 'axios'

export default class OrganizationService {
  static async getOrganizations<R = IOrganization[]>(): Promise<R> {
    return await RequestService.Get<R>(
      ApplicationAPI.Organizations,
      undefined,
      undefined,
      undefined,
      true
    )
  }

  static async createOrganization<T = any, R = AxiosResponse<T>>(
    data: IOrganizationInput
  ): Promise<R> {
    return await RequestService.Post<R>(ApplicationAPI.Organizations, data)
  }

  static async updateOrganization<T = any, R = AxiosResponse<T>>(
    organizationId: string,
    data: IOrganizationInput
  ): Promise<R> {
    return await RequestService.Put<R>(
      `${ApplicationAPI.Organizations}/${organizationId}`,
      data
    )
  }

  static async getOrganizationUsers<R = IOrganizationUser[]>(): Promise<R> {
    return await RequestService.Get<R>(`${ApplicationAPI.Organizations}/users`)
  }

  static async removeOrganizationUser<R = any>(id: string): Promise<R> {
    return await RequestService.Delete<R>(
      `${ApplicationAPI.Organizations}/users/${id}`
    )
  }

  static async deleteOrganization<R = any>(id: string): Promise<R> {
    return await RequestService.Delete<R>(
      `${ApplicationAPI.Organizations}/${id}`
    )
  }
}
