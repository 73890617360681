import { NavLink } from 'react-router-dom'

interface HeaderNavItemProps {
  to: string
  title: string
}

export default function HeaderNavItem(props: HeaderNavItemProps) {
  return (
    <li className="pt-4 pb-4 transition ease-in-out hover:-translate-y-1 hover:scale-110">
      <NavLink
        to={props.to}
        className="text-lg font-semibold no-underline"
      >
        {props.title}
      </NavLink>
    </li>
  )
}
