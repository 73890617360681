import DeviceService from 'api/services/DeviceService'
import IDevice from 'interfaces/devices/IDevice'
import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useMemo,
  useReducer
} from 'react'
import SelectionMode from './SelectionMode'

enum Action {
  AddSelectedDevice = 'ADD_SELECTED_DEVICE',
  RemoveSelectedDevice = 'REMOVE_SELECTED_DEVICE',
  SetSelectionMode = 'SET_SELECTION_MODE',
  Reset = 'RESET',
  SetDevices = 'SET_DEVICES'
}

interface IDeviceContextProps {
  children?: ReactElement
  selectionMode?: SelectionMode
  selectedDevices?: IDevice[]
}

interface IDeviceContext {
  devices: IDevice[]
  /** The device currently displayed or selected as active */
  selectedDevice: IDevice | undefined | null
  /** Selected device, this property is used when selecting the device to be included in the playlist or when viewing content to find out which device is currently selected. */
  selectedDevices: IDevice[]
  /** Get the selection mode, if the mode is single, it will be possible to select only one device, otherwise it will be possible to select multiple device. */
  selectionMode: SelectionMode | undefined
  /** Set the selection mode, if the mode is single, it will be possible to select only one device, otherwise it will be possible to select multiple device. */
  setSelectionMode: (value: SelectionMode) => any
  selectDevice: (value: IDevice) => any
  unselectDevice: (value: IDevice) => any
  isSelected: (value: IDevice) => boolean
  fetchDevices: () => Promise<IDevice[]>
}

interface IDeviceContextState {
  devices: IDevice[]
  selectedDevice: IDevice | undefined | null
  selectedDevices: IDevice[]
  selectionMode: SelectionMode | undefined
}

interface IDeviceContextAction {
  type: Action
  selectedDevice?: IDevice
  selectionMode?: SelectionMode
  deviceToAdd?: IDevice
  deviceToRemove?: IDevice
  devices?: IDevice[]
}

const Context = createContext<IDeviceContext | undefined>(undefined)

function init(initialArgs: IDeviceContextProps): IDeviceContextState {
  var selectionMode = SelectionMode.Single
  if (initialArgs.selectionMode) {
    selectionMode = initialArgs.selectionMode
  }

  var selectedDevices = new Array<IDevice>()
  if (initialArgs.selectedDevices) {
    selectedDevices = Array.from(initialArgs.selectedDevices)
  }

  return {
    devices: new Array<IDevice>(),
    selectedDevice: undefined,
    selectedDevices: selectedDevices,
    selectionMode: selectionMode
  }
}

function reducer(
  state: IDeviceContextState,
  action: IDeviceContextAction
): IDeviceContextState {
  switch (action.type) {
    case Action.AddSelectedDevice:
      // if deviceToAdd is invalid return state to avoid refresh
      if (!action.deviceToAdd) return state

      if (
        (state.selectionMode as SelectionMode) ===
        (SelectionMode.Multiple as SelectionMode)
      ) {
        // If device doesnt exists then add the device
        if (
          state.selectedDevices.findIndex((value) => {
            return action.deviceToAdd?.id === value.id
          }) < 0
        ) {
          state.selectedDevices = Array.from(state.selectedDevices)
          state.selectedDevices.push(action.deviceToAdd)
          state.selectedDevice = undefined
        }
      } else {
        state.selectedDevices = []
        state.selectedDevices.push(action.deviceToAdd)
        state.selectedDevice = action.deviceToAdd
      }

      return {
        ...state
      }
    case Action.RemoveSelectedDevice:
      // if deviceToRemove is invalid return state to avoid refresh
      if (!action.deviceToRemove) return state

      // If device is included then remove the device
      var index = state.selectedDevices.findIndex((value) => {
        return action.deviceToRemove?.id === value.id
      })

      if (index < 0) {
        // The device is not present in the collection
        return state
      }

      // Remove the device
      state.selectedDevices.splice(index, 1)
      state.selectedDevices = Array.from(state.selectedDevices)

      if (
        (state.selectionMode as SelectionMode) ===
        (SelectionMode.Single as SelectionMode)
      ) {
        state.selectedDevice = undefined
      }

      return {
        ...state
      }
    case Action.SetSelectionMode:
      return {
        ...state,
        selectionMode: action.selectionMode
      }

    case Action.SetDevices:
      if (!action.devices) {
        return state
      }

      return {
        ...state,
        devices: action.devices,
        selectedDevices: [],
        selectedDevice: undefined
      }
    default:
      throw new Error()
  }
}

export const DeviceContext = (props: IDeviceContextProps) => {
  const [state, dispatch] = useReducer(reducer, props, init)

  const context = useMemo(() => {
    return {
      devices: state.devices,
      selectedDevice: state.selectedDevice,
      selectedDevices: state.selectedDevices,
      selectionMode: state.selectionMode,
      setSelectionMode(value: SelectionMode) {
        dispatch({ type: Action.SetSelectionMode, selectionMode: value })
      },
      selectDevice: (value: IDevice) => {
        dispatch({ type: Action.AddSelectedDevice, deviceToAdd: value })
      },
      unselectDevice: (value: IDevice) => {
        dispatch({ type: Action.RemoveSelectedDevice, deviceToRemove: value })
      },
      isSelected: (value: IDevice) => {
        const result =
          state.selectedDevices.findIndex((selectedDevice) => {
            return selectedDevice.id === value.id
          }) >= 0
        return result
      },
      fetchDevices: async () => {
        const devices = await DeviceService.getDevices()
        dispatch({ type: Action.SetDevices, devices: devices })
        return devices
      }
    }
  }, [
    state.selectedDevice,
    state.selectedDevices,
    state.devices,
    state.selectionMode
  ])

  return <Context.Provider value={context}>{props.children}</Context.Provider>
}

export const useDevices = () => {
  const DeviceContext = useContext(Context)
  return DeviceContext
}

interface DeviceConsumerProps {
  children: (value: IDeviceContext | undefined) => ReactNode
}

export const DeviceConsumer = (props: DeviceConsumerProps) => {
  return <Context.Consumer>{props.children}</Context.Consumer>
}
