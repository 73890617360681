import PaymentCancelled from 'components/popups/PaymentCancelled'
import PaymentError from 'components/popups/PaymentError'
import PaymentSuccess from 'components/popups/PaymentSuccess'
import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useMemo,
  useReducer
} from 'react'

export enum PaymentState {
  None,
  Success,
  Error,
  Cancelled
}

interface IPaymentContextProps {
  children?: ReactElement
  state?: PaymentState
}

interface IPaymentContext {
  setPaymentSuccess: (transactionId: string, amount: number) => void
  setPaymentError: VoidFunction
  setPaymentCancel: VoidFunction
  resetPayment: VoidFunction
}

interface IPaymentContextState {
  paymentState: PaymentState
  transactionId?: string
  amount?: number
}

enum Action {
  SetPaymentSuccess = 'SET_PAYMENT_SUCCESS',
  SetPaymentCancel = 'SET_PAYMENT_CANCEL',
  SetPaymentError = 'SET_PAYMENT_ERROR',
  ResetPayment = 'RESET_PAYMENT_STATE'
}

interface IPaymentContextAction {
  type: Action
  transactionId?: string
  amount?: number
}

const Context = createContext<IPaymentContext | undefined>(undefined)

function init(initialArgs: IPaymentContextProps): IPaymentContextState {
  return {
    paymentState: initialArgs.state ?? PaymentState.None
  }
}

function reducer(
  state: IPaymentContextState,
  action: IPaymentContextAction
): IPaymentContextState {
  switch (action.type) {
    case Action.SetPaymentSuccess:
      return {
        paymentState: PaymentState.Success,
        transactionId: action.transactionId,
        amount: action.amount
      }
    case Action.SetPaymentCancel:
      return {
        paymentState: PaymentState.Cancelled
      }
    case Action.SetPaymentError:
      return {
        paymentState: PaymentState.Error
      }
    case Action.ResetPayment:
      return {
        paymentState: PaymentState.None
      }
    default:
      throw new Error()
  }
}

export const PaymentContext = (props: IPaymentContextProps) => {
  const [state, dispatch] = useReducer(reducer, props, init)

  const context = useMemo(() => {
    return {
      setPaymentSuccess(transactionId: string, amount: number) {
        dispatch({
          type: Action.SetPaymentSuccess,
          transactionId: transactionId,
          amount: amount
        })
      },
      setPaymentCancel() {
        dispatch({ type: Action.SetPaymentCancel })
      },
      setPaymentError() {
        dispatch({ type: Action.SetPaymentError })
      },
      resetPayment() {
        dispatch({ type: Action.ResetPayment })
      }
    }
  }, [])

  return (
    <Context.Provider value={context}>
      {props.children}
      {state.paymentState === (PaymentState.Success as PaymentState) && (
        <PaymentSuccess
          transactionId={state.transactionId ?? ''}
          amount={state.amount ?? 0}
        />
      )}
      {state.paymentState === (PaymentState.Cancelled as PaymentState) && (
        <PaymentCancelled
          onClose={() => {
            context.resetPayment()
          }}
        />
      )}
      {state.paymentState === (PaymentState.Error as PaymentState) && (
        <PaymentError
          onClose={() => {
            context.resetPayment()
          }}
        />
      )}
    </Context.Provider>
  )
}

export const usePayment = () => {
  const PaymentContext = useContext(Context)
  return PaymentContext
}

interface PaymentConsumerProps {
  children: (value: IPaymentContext | undefined) => ReactNode
}

export const PaymentConsumer = (props: PaymentConsumerProps) => {
  return <Context.Consumer>{props.children}</Context.Consumer>
}
