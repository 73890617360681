import { useState } from 'react'
import IOrganization from 'interfaces/organizations/IOrganization'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import StorefrontIcon from '@mui/icons-material/Storefront'
import { IconButton, Tooltip } from '@mui/material'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import IPlaylist from 'interfaces/playlists/IPlaylist'
import moment from 'moment'
import { AxiosError } from 'axios'
import PlaylistService from 'api/services/PlaylistService'
import AuthorizedMedia from 'components/media-element/AuthorizedMedia'
import { usePlaylist } from 'middlewares/PlaylistContext'
import { toast } from 'react-toastify'
import OrganizationService from 'api/services/OrganizationService'

interface IOrganizationItemProps {
  organization: IOrganization
  animationDelay?: number
  selected: boolean
  onSelected: (organization: IOrganization) => any
  isReadOnly?: boolean
  onEdit?: (organization: IOrganization) => any
}

export default function OrganizationItem({
  organization,
  animationDelay,
  selected,
  onSelected,
  isReadOnly = false,
  onEdit
}: IOrganizationItemProps) {
  const [hidden, setHidden] = useState(false)
  const [disposed, setDisposed] = useState(false)

  const onClick = () => {
    onSelected(organization)
  }

  const edit = () => {
    if (onEdit) onEdit(organization)
  }

  const onDelete = () => {
    if (organization.id) {
      OrganizationService.deleteOrganization(organization.id)
        .then(onDeleted)
        .catch(onError)
    }
  }

  const onDeleted = () => {
    toast.success(
      'La richiesta di cancellazione é stata correttamente inviata! La sua richiesta verrá gestita in 90 giorni.'
    )
  }

  const onError = (error: AxiosError) => {
    console.log(error)
    toast.error(error.message)
  }

  if (disposed) {
    return <></>
  }

  return (
    <tr
      className={`h-16 ${
        selected ? 'bg-slate-200 ' : 'bg-white hover:bg-slate-100'
      }  wow animate__animated animate__fadeIn animated`}
      style={{
        animationDelay: `${animationDelay ? animationDelay : '0'}ms`
      }}
      onClick={onClick}
    >
      <td className="border-b border-slate-100 p-4 pl-8 text-slate-500 items-center">
        <div className="flex flex-row">
          <ListItemAvatar>
            <Avatar>
              <StorefrontIcon />
            </Avatar>
          </ListItemAvatar>
          <div className="self-center text-center">{organization.name}</div>
        </div>
      </td>
      <td className="border-b border-slate-100 p-4 text-slate-500">
        {organization.description}
      </td>
      {!isReadOnly && (
        <td className="border-b border-slate-100 p-4 pr-8 text-slate-500">
          <Tooltip title="Modifica">
            <IconButton edge="end" aria-label="Modifica" onClick={edit}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </td>
      )}
      {!isReadOnly && (
        <td className="border-b border-slate-100 p-4 pr-8 text-slate-500">
          <Tooltip title="Richiedi cancellazione">
            <IconButton
              edge="end"
              aria-label="Richiedi cancellazione"
              onClick={onDelete}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </td>
      )}
    </tr>
  )
}
