import DevicesIcon from '@mui/icons-material/Devices'
import StorefrontIcon from '@mui/icons-material/Storefront'
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import ManagementRoutes from 'routes/ManagementRoutes'
import BottomSidebarItem from './BottomSidebarItem'

export default function BottomNavigationBar() {
  return (
    <nav className="block sm:hidden bg-primary-700 shadow-inner h-20 min-w-full bottom-0 fixed">
      <ul className="w-full h-full flex flex-row list-none">
        <BottomSidebarItem
          to={ManagementRoutes.Shopwindows}
          icon={<StorefrontIcon fontSize='large' />}
        />

        <BottomSidebarItem
          to={ManagementRoutes.Devices}
          icon={<DevicesIcon fontSize='large' />}
        />

        <BottomSidebarItem
          to={ManagementRoutes.Playlists}
          icon={<PlaylistPlayIcon fontSize='large' />}
        />

        <BottomSidebarItem
          to={ManagementRoutes.Medias}
          icon={<ImageOutlinedIcon fontSize='large' />}
        />

        <BottomSidebarItem
          to={ManagementRoutes.User.Profile}
          icon={<AccountCircleOutlinedIcon fontSize='large' />}
        />
      </ul>
    </nav>
  )
}
