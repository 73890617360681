import * as React from 'react'
import Box from '@mui/material/Box'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import { useLocation, useNavigate } from 'react-router-dom'
import AdministrationRoutes from 'routes/AdministrationRoutes'
import TranslateIcon from '@mui/icons-material/Translate'

export default function AdminBottomNavigationBar() {
  const [value, setValue] = React.useState(0)
  const navigate = useNavigate()
  const location = useLocation()

  React.useEffect(() => {
    switch (location.pathname) {
      case AdministrationRoutes.Terms:
        setValue(0)
        break
    }
  }, [location])

  return (
    <div className="block sm:hidden bg-white shadow-inner h-20 min-w-full bottom-0 fixed">
      <Box>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            switch (newValue) {
              case 0:
                navigate(AdministrationRoutes.Terms)
                break
            }
          }}
        >
          <BottomNavigationAction label="Termini" icon={<TranslateIcon />} />
        </BottomNavigation>
      </Box>
    </div>
  )
}
