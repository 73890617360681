import * as process from 'process'

export const WebServerAddress =
  process.env.NODE_ENV === 'production'
    ? 'https://novaads.it'
    : 'https://localhost:7252'

export const CdnAddress =
  process.env.NODE_ENV === 'production'
    ? 'https://cdn.nicoladonati.it'
    : 'https://localhost:44444'

export const ApplicationName = 'NovaAds.Web'

export const QueryParameterNames = {
  ReturnUrl: 'returnUrl',
  Message: 'message'
}

export const ApplicationAPI = {
  Newsletter: {
    RequestSubscription: `${WebServerAddress}/api/newsletter/RequestSubscription`,
    Subscription: `${WebServerAddress}/api/newsletter/Subscription`,
    Unsubscribe: `${WebServerAddress}/api/newsletter/Unsubscribe`,
    SubscribeCurrentUser: `${WebServerAddress}/api/newsletter/SubscribeCurrentUser`,
    UnsubscribeCurrentUser: `${WebServerAddress}/api/newsletter/UnsubscribeCurrentUser`
  },
  Medias: `${WebServerAddress}/api/medias`,
  ShopWindows: {
    Base: `${WebServerAddress}/api/shopwindows`,
    AddDevices: `${WebServerAddress}/api/shopwindows/add-devices`
  },
  Playlists: `${WebServerAddress}/api/playlists`,
  PlaylistAssignment: `${WebServerAddress}/api/playlists/assignment`,
  Organizations: `${WebServerAddress}/api/organizations`,
  Devices: {
    Base: `${WebServerAddress}/api/devices`,
    Configuration: `${WebServerAddress}/api/devices/configuration`
  },
  PlaylistMediaElement: `${WebServerAddress}/api/PlaylistMediaElement`,
  Licenses: {
    GetAllLicenses: `${WebServerAddress}/api/licenses`,
    UnassignDevice: `${WebServerAddress}/api/licenses/unassign`,
    AssignDevice: `${WebServerAddress}/api/licenses/assign`
  },
  Users: {
    Base: `${WebServerAddress}/api/user`,
    Notifications: `${WebServerAddress}/api/user/notifications`
  },
  Profile: `${WebServerAddress}/api/profile`,
  Invitations: `${WebServerAddress}/api/Invitations`,
  Payments: {
    GetAllPayments: `${WebServerAddress}/api/payments/payments`,
    CreateOrder: `${WebServerAddress}/api/payments/create-order`,
    CapturePayment: `${WebServerAddress}/api/payments/capture-payment`,
    CreateSubscription: `${WebServerAddress}/api/payments/create-subscription`,
    AvailableSubscriptionPaymentMethods: `${WebServerAddress}/api/payments/available-subscription-payment-methods`,
    AvailablePaymentMethods: `${WebServerAddress}/api/payments/available-payment-methods`
  },
  Authentication: {
    SignIn: `${WebServerAddress}/api/authentication/signin`,
    IsSignedIn: `${WebServerAddress}/api/authentication/is-signedin`,
    GetUserInfo: `${WebServerAddress}/api/authentication/user-info`,
    SignOut: `${WebServerAddress}/api/authentication/signout`,
    ExternalAuthenticationSchemas: `${WebServerAddress}/api/authentication/external-authentication-schemas`,
    ExternalAuthenticationSignIn: `${WebServerAddress}/api/authentication/external-signin`
  },
  Account: {
    Create: `${WebServerAddress}/api/account/register`,
    ResetPassword: `${WebServerAddress}/api/account/reset-password`,
    ConfirmAccount: `${WebServerAddress}/api/account/confirm-account`,
    ChangePassword: `${WebServerAddress}/api/account/change-password`,
    ChangeEmail: `${WebServerAddress}/api/account/change-email`,
    ConfirmEmailChange: `${WebServerAddress}/api/account/confirm-email-change`,
    Delete: `${WebServerAddress}/api/account/delete-account`
  },
  Store: {
    Products: `${WebServerAddress}/api/store/products`,
    Orders: `${WebServerAddress}/api/store/orders`,
    Subscription: `${WebServerAddress}/api/store/subscription`
  },
  DeviceNotification: {
    SendMessage: `${WebServerAddress}/api/clients/notify`
  },
  Invoice: `${WebServerAddress}/api/payments/invoice`,
  Terms: `${WebServerAddress}/api/terms`,
  Localization:{
    CountriesEndpoint: `${WebServerAddress}/api/localization/countries`,
  }
}
