import Exception from './Exception'

export default class ForbiddenException extends Exception {
  constructor() {
    super('Accesso Vietato')

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ForbiddenException.prototype)
  }
}
