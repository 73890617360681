const ManagementRoutes = {
  Dashboard: '/dashboard',
  Shopwindows: '/dashboard/shopwindows',
  Devices: '/dashboard/devices',
  Playlists: '/dashboard/playlists',
  Medias: '/dashboard/medias',
  Store: '/dashboard/store',
  User: {
    BaseRoot: '/dashboard/user',
    Profile: '/dashboard/user/profile',
    Licenses: '/dashboard/user/licenses',
    Invitations: '/dashboard/user/invitations',
    Orders: '/dashboard/user/orders',
    Payments: '/dashboard/user/payments',
    Organizations: '/dashboard/user/organizations'
  },
  Organization: {
    BaseRoot: '/dashboard/organization',
    Summary: '/dashboard/organization/summary',
    Team: '/dashboard/organization/team'
  }
}

export default ManagementRoutes
