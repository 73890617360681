import { IsDevelopment } from 'EnvironmentVariables'
import { createSearchParams, NavigateFunction } from 'react-router-dom'
import { toast } from 'react-toastify'
import ApplicationRoutes from 'routes/ApplicationRoutes'
import ErrorUtility from 'utilities/ErrorUtility'

export default class PromiseUtility {
  static delay(ms: number) {
    return new Promise((resolve) => {
      const timer = setTimeout(() => {
        clearTimeout(timer)
        resolve(undefined)
      }, ms)
    })
  }

  static wrapPromise<T>(promise: () => Promise<T>) {
    let status = 'pending'
    let result: T
    let suspend = promise().then(
      (res) => {
        status = 'success'
        result = res
      },
      (err) => {
        status = 'error'
        result = err
      }
    )
    return {
      read(): T | undefined {
        if (status === 'pending') {
          throw suspend
        } else if (status === 'error') {
          throw result
        } else if (status === 'success') {
          return result
        }
      }
    }
  }

  /**
   * Catch the exception and display a toast containing the error message
   * @param promise, The request promise
   * @returns a promise with a catch
   */
  static onErrorDisplayToast<T>(promise: Promise<T>): Promise<T> {
    promise.catch((error) => {
      if (IsDevelopment) {
        // If developing write the errors in console
        console.log(error)
      }

      toast.error(ErrorUtility.GetErrorMessage(error))
    })

    return promise
  }

  /**
   * Display a toast containing the error message and propagate the exception
   * @param promise, The request promise
   * @returns the request promise
   */
  static onErrorDisplayToastAndThrow<T>(promise: Promise<T>): Promise<T> {
    promise.catch((error) => {
      if (IsDevelopment) {
        // If developing write the errors in console
        console.log(error)
      }
      toast.error(ErrorUtility.GetErrorMessage(error))
      throw error
    })

    return promise
  }

  /**
   * Catch the exception and set the error message
   * @param promise, The request promise
   * @param setter, The function to set the error
   * @returns a promise with a catch
   */
  static onErrorSetState<T>(
    promise: Promise<T>,
    setter: React.Dispatch<React.SetStateAction<string | undefined>>
  ): Promise<T> {
    promise.catch((error) => {
      if (IsDevelopment) {
        // If developing write the errors in console
        console.log(error)
      }

      setter(ErrorUtility.GetErrorMessage(error))
    })

    return promise
  }

  /**
   * Set the error message and propagate the exception
   * @param promise, The request promise
   * @returns the request promise
   */
  static onErrorSetStateAndThrow<T>(
    promise: Promise<T>,
    setter: React.Dispatch<React.SetStateAction<string | undefined>>
  ): Promise<T> {
    promise.catch((error) => {
      if (IsDevelopment) {
        // If developing write the errors in console
        console.log(error)
      }

      setter(ErrorUtility.GetErrorMessage(error))
    })

    return promise
  }

  /**
   * Catch the exception and navigate to error page
   * @param promise, The request promise
   * @param navigate, The function to navigate
   * @returns a promise with a catch
   */
  static onErrorNavigate<T>(
    promise: Promise<T>,
    navigate: NavigateFunction
  ): Promise<T> {
    promise.catch((error) => {
      if (IsDevelopment) {
        // If developing write the errors in console
        console.log(error)
      }

      navigate({
        pathname: ApplicationRoutes.Error,
        search: `${createSearchParams({
          error: ErrorUtility.GetErrorCode(error),
          description: ErrorUtility.GetErrorMessage(error)
        })}`
      })
    })

    return promise
  }
}
