import OrganizationService from 'api/services/OrganizationService'
import { AxiosError } from 'axios'
import OrganizationItem from 'components/rows/OrganizationItem'
import IOrganization from 'interfaces/organizations/IOrganization'
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Button from '@mui/material/Button'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import CreateOrUpdateOrganization from 'components/popups/CreateOrUpdateOrganization'

interface IOrganizationListProps {
  currentOrganization: IOrganization | undefined
  onSelectedOrganizationChanged: (organization: IOrganization) => any
  isReadOnly?: boolean
  showTitle?: boolean
}

export default function OrganizationList({
  currentOrganization,
  onSelectedOrganizationChanged,
  isReadOnly,
  showTitle
}: IOrganizationListProps) {
  const [isCreatingOrganization, setIsCreatingOrganization] = useState(false)
  const [isEditingOrganization, setIsEditingOrganization] = useState(false)
  const [selectedOrganization, setSelectedOrganization] =
    React.useState<IOrganization | null>(null)

  const [organizations, setOrganizations] = useState<
    IOrganization[] | undefined
  >(undefined)

  const fetchOrganizzations = useCallback(() => {
    OrganizationService.getOrganizations()
      .then(updateOrganizations)
      .catch(onError)
  }, [])

  useEffect(() => {
    fetchOrganizzations()
  }, [fetchOrganizzations])

  const onEditOrganization = (organization: IOrganization) => {
    setSelectedOrganization(organization)
    setIsEditingOrganization(true)
  }

  const onOrganizationEdited = () => {
    setSelectedOrganization(null)
    setIsEditingOrganization(false)
    fetchOrganizzations()
  }

  const createOrganization = () => {
    setSelectedOrganization(null)
    setIsCreatingOrganization(true)
  }

  const onOrganizationCreated = () => {
    setSelectedOrganization(null)
    setIsCreatingOrganization(false)
    fetchOrganizzations()
  }

  const updateOrganizations = (organizations: IOrganization[]) => {
    setOrganizations(organizations)
  }

  const onError = (error: AxiosError) => {
    console.log(error.message)
    toast.error(error.message)
  }

  const onSelected = (organization: IOrganization) => {
    onSelectedOrganizationChanged(organization)
  }

  return (
    <div className="min-w-0 break-words bg-white rounded mb-6 xl:mb-0 mt-10 mx-4">
      <div className="p-4">
        {showTitle ?? (
          <h3 className="mb-2 text-xl font-semibold font-heading">
            Le tue organizzazioni
          </h3>
        )}

        <table className="border-collapse table-auto w-full text-sm">
          <thead>
            <tr>
              <th className="border-b font-medium p-4 pl-8 pt-0 pb-3 text-slate-500 text-left w-5/12">
                Organizzazione
              </th>
              <th className="border-b font-medium p-4 pt-0 pb-3 text-slate-500 text-left w-5/12">
                Descrizione
              </th>
              {!isReadOnly && (
                <th className="border-b font-medium p-4 pt-0 pb-3 text-slate-500 text-left w-1/12">
                  Modifica
                </th>
              )}
              {!isReadOnly && (
                <th className="border-b font-medium p-4 pt-0 pb-3 text-slate-500 text-left w-1/12">
                  Elimina
                </th>
              )}
            </tr>
          </thead>
          <tbody className="bg-white">
            {!isReadOnly && (
              <>
                <tr>
                  <td
                    className="border-b border-slate-100 p-4 text-white "
                    colSpan={8}
                  >
                    <Button
                      style={{
                        textTransform: 'none'
                      }}
                      fullWidth
                      aria-label="Crea una nuova organizzazione"
                      onClick={createOrganization}
                    >
                      <GroupAddIcon fontSize="medium" className="mr-2" />
                      <span className="font-body">
                        Crea una nuova organizzazione
                      </span>
                    </Button>
                  </td>
                </tr>
              </>
            )}

            {organizations &&
              organizations.map((value, index) => {
                return (
                  <OrganizationItem
                    key={value.id}
                    organization={value}
                    selected={
                      !!currentOrganization &&
                      !!currentOrganization.id &&
                      currentOrganization.id === value.id
                    }
                    animationDelay={index < 10 ? (index + 1) * 2 * 100 : 0}
                    onSelected={onSelected}
                    isReadOnly={isReadOnly}
                    onEdit={onEditOrganization}
                  />
                )
              })}
          </tbody>
        </table>
      </div>
      {isCreatingOrganization && (
        <CreateOrUpdateOrganization
          organization={undefined}
          onClose={(e) => {
            setIsCreatingOrganization(false)
          }}
          onOrganizationCreated={onOrganizationCreated}
        />
      )}
      {isEditingOrganization && (
        <CreateOrUpdateOrganization
          organization={selectedOrganization}
          onClose={(e) => {
            setIsCreatingOrganization(false)
          }}
          onOrganizationUpdated={onOrganizationEdited}
        />
      )}
    </div>
  )
}
