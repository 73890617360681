import React, { ReactElement } from 'react'
import { NavLink } from 'react-router-dom'

interface BottomSidebarItemProps {
  to: string
  icon: ReactElement
}

export default function BottomSidebarItem({
  to = '/',
  icon
}: BottomSidebarItemProps) {
  return (
    <li
      className='flex-1'
    >
      <NavLink
        className={({ isActive }) =>
          'flex items-center justify-center uppercase no-underline h-full text-white hover:text-primary-500 hover:bg-primary-100' +
          (isActive
            ? ' bg-primary-500' : '')
        }
        to={to}
      >
        <div className="inline">{icon}</div>
      </NavLink>
    </li>
  )
}
