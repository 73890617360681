import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useState
} from 'react'
import OrganizationList from 'components/list/OrganizationList'
import IOrganization from 'interfaces/organizations/IOrganization'
import domainService from 'api/services/DomainService'

interface ISelectOrganizationProps {
  onClose?: VoidFunction
  onOrganizationChanged?: VoidFunction
}

export default function SelectOrganization({
  onClose,
  onOrganizationChanged
}: ISelectOrganizationProps) {
  const [error, setError] = useState<string | undefined>(undefined)

  const [currentOrganization, setCurrentOrganization] = useState<
    IOrganization | undefined
  >(undefined)

  const [hidden, setHidden] = useState(false)

  useEffect(() => {
    const organization = domainService.GetCurrentOrganization()
    setCurrentOrganization(organization)
  }, [])

  const OrganizationChanged = useCallback(() => {
    setHidden(true)
    const timer = setTimeout(function () {
      clearTimeout(timer)
      if (onOrganizationChanged != null) {
        onOrganizationChanged()
      }
    }, 600)
  }, [onOrganizationChanged])

  const onSubmit = useCallback(
    async (e?: React.BaseSyntheticEvent) => {
      e?.preventDefault()
      setError(undefined)

      if (!currentOrganization) {
        setError('Seleziona un organizzazione !')
        return
      }

      domainService.updateOrganization(currentOrganization)

      OrganizationChanged()
    },
    [currentOrganization, OrganizationChanged]
  )

  const close: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault()
    setHidden(true)
    const timer = setTimeout(function () {
      clearTimeout(timer)
      if (onClose != null) {
        onClose()
      }
    }, 600)
  }

  const onSelectedOrganizationChanged = (organization: IOrganization) => {
    setCurrentOrganization(organization)
  }

  return (
    <div
      tabIndex={-1}
      className={`overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-modal h-full justify-center items-center flex bg-black/[.3]  ${
        hidden
          ? 'wow animate__animated animate__fadeOut animated'
          : 'wow animate__animated animate__fadeIn animated'
      }  `}
      aria-modal="true"
      role="dialog"
    >
      <div className="relative p-4 w-full max-w-xl md:max-w-5xl h-auto">
        <div className=" bg-white rounded-lg shadow">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-500 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            onClick={close}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="p-10 w-auto h-auto">
            <section>
              <div className="container max-h-full lg:max-h-[75vh]">
                <form
                  className="flex flex-wrap pt-6"
                  onSubmit={(e) => e.preventDefault()}
                  noValidate
                >
                  <div className="lg:flex lg:flex-col w-full">
                    <div
                      className={`w-full mx-auto lg:mx-0 my-auto ${
                        hidden
                          ? 'wow animate__animated animate__fadeOut animated'
                          : 'wow animate__animated animate__fadeIn animated'
                      }  `}
                      data-wow-delay=".3s"
                    >
                      <h4 className="mb-8 text-3xl">
                        Seleziona un organizzazione
                      </h4>
                      <OrganizationList
                        currentOrganization={currentOrganization}
                        onSelectedOrganizationChanged={
                          onSelectedOrganizationChanged
                        }
                        isReadOnly={true}
                      />
                      {error && (
                        <p className="text-xs mt-2 text-center text-red-500 font-semibold">
                          {error}
                        </p>
                      )}
                      <div className="flex flex-row flex-wrap md:flex-nowrap mx-auto mt-8">
                        <button
                          className="w-full md:w-1/2 block sm:inline-block py-4 px-6 mb-4 sm:mb-0 sm:mr-3 text-xs text-gray-500 hover:text-gray-600 text-center font-semibold leading-none bg-white border border-gray-200 hover:border-gray-300 rounded"
                          type="button"
                          onClick={close}
                        >
                          Annulla
                        </button>
                        <button
                          className="w-full md:w-1/2 block sm:inline-block py-4 px-6 text-xs text-white text-center font-semibold leading-none bg-primary-500 hover:bg-primary-500 rounded"
                          type="button"
                          onClick={onSubmit}
                        >
                          Seleziona
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}
