import React, { MouseEventHandler, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import ProgressButton, { State } from 'components/buttons/ProgressButton'
import OrganizationService from 'api/services/OrganizationService'
import IOrganization from 'interfaces/organizations/IOrganization'
import SubscriptionsIcon from '@mui/icons-material/Subscriptions'
import DescriptionIcon from '@mui/icons-material/Description'
import ErrorMessageReporter from 'components/react-hook-forms-utils/ErrorMessageReporter'
import IOrganizationInput from 'interfaces/organizations/IOrganizationInput'
import { CdnAddress } from 'api/ApiConstants'

interface ICreateOrUpdateOrganizationProps {
  organization: IOrganization | null | undefined
  onClose?: MouseEventHandler<HTMLButtonElement> | undefined
  onOrganizationCreated?: () => void
  onOrganizationUpdated?: () => void
}

export default function CreateOrUpdateOrganization({
  organization,
  onClose,
  onOrganizationCreated,
  onOrganizationUpdated
}: ICreateOrUpdateOrganizationProps) {
  const [progressButtonState, setProgressButtonState] = useState(State.Unsetted)
  const [error, setError] = useState<string | undefined>(undefined)

  const [hidden, setHidden] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const onSubmit = async (data: FieldValues, e?: React.BaseSyntheticEvent) => {
    e?.preventDefault()

    setError(undefined)
    setProgressButtonState(State.Loading)

    if (organization) {
      await updateOrganization(data)
    } else {
      await registerOrganization(data)
    }
  }

  const registerOrganization = async (data: FieldValues) => {
    const input: IOrganizationInput = {
      name: data.name,
      description: data.description
    }

    try {
      await OrganizationService.createOrganization(input)
      OrganizationRegistered()
    } catch (error) {
      onError(error)
    }
  }

  const updateOrganization = async (data: FieldValues) => {
    if (organization && organization.id) {
      const input: IOrganizationInput = {
        name: data.name,
        description: data.description
      }

      try {
        await OrganizationService.updateOrganization(organization.id, input)
        OrganizationUpdated()
      } catch (error) {
        onError(error)
      }
    }
  }

  const onError = (error: any) => {
    setProgressButtonState(State.Unsetted)
    console.log(error)
    setError(error.message)
    throw error
  }

  const OrganizationRegistered = () => {
    setHidden(true)
    const timer = setTimeout(function () {
      clearTimeout(timer)
      if (onOrganizationCreated != null) {
        onOrganizationCreated()
      }
    }, 600)
  }

  const OrganizationUpdated = () => {
    setHidden(true)
    const timer = setTimeout(function () {
      clearTimeout(timer)
      if (onOrganizationUpdated != null) {
        onOrganizationUpdated()
      }
    }, 600)
  }

  const close: MouseEventHandler<HTMLButtonElement> = (e) => {
    setHidden(true)
    const timer = setTimeout(function () {
      clearTimeout(timer)
      if (onClose != null) {
        onClose(e)
      }
    }, 600)
  }

  return (
    <div
      tabIndex={-1}
      className={`overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-modal h-full justify-center items-center flex bg-black/[.3]  ${
        hidden
          ? 'wow animate__animated animate__fadeOut animated'
          : 'wow animate__animated animate__fadeIn animated'
      }  `}
      aria-modal="true"
      role="dialog"
    >
      <div className="relative p-4 w-full max-w-xl md:max-w-5xl h-auto">
        <div className=" bg-white rounded-lg shadow">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-500 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            onClick={close}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Chiudi</span>
          </button>
          <div className="p-10 text-center w-auto h-auto">
            <section className="relative">
              <div className="hidden lg:block absolute inset-0 w-1/2 ml-auto">
                <div
                  className={`flex items-center h-full  ${
                    hidden
                      ? 'wow animate__animated animate__fadeOut animated'
                      : 'wow animate__animated animate__fadeIn animated'
                  }  `}
                  data-wow-delay=".3s"
                >
                  <img
                    className="lg:max-w-md mx-auto"
                    src={`${CdnAddress}/static/assets/imgs/illustrations/phase-1.webp`}
                    alt="NovaAds"
                  />
                </div>
              </div>
              <div className="container">
                <form
                  className="relative flex flex-wrap pt-6"
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                >
                  <div className="lg:flex lg:flex-col w-full lg:w-1/2 py-6 ">
                    <div
                      className={`w-full max-w-lg mx-auto lg:mx-0 my-auto ${
                        hidden
                          ? 'wow animate__animated animate__fadeOut animated'
                          : 'wow animate__animated animate__fadeIn animated'
                      }  `}
                      data-wow-delay=".3s"
                    >
                      <h4 className="mb-6 text-3xl">
                        {organization
                          ? 'Modifica la tua organizzazione'
                          : 'Crea una nuova organizzazione'}
                      </h4>
                      {/* name */}
                      <div
                        className={`flex pr-4 bg-gray-50 rounded border ${
                          errors.name
                            ? 'border-red-500'
                            : 'border-gray-200 mb-4'
                        }`}
                      >
                        <input
                          {...register('name', {
                            required:
                              'Inserisci qui il nome della tua organization'
                          })}
                          className="w-full pl-4 py-4 text-xs placeholder-gray-500 font-semibold leading-none bg-gray-50 outline-none"
                          type="text"
                          placeholder={
                            'Inserisci qui il nome della tua organizzazione'
                          }
                          defaultValue={organization?.name}
                        />
                        <SubscriptionsIcon className="h-6 w-6 ml-4 my-auto text-gray-300" />
                      </div>
                      <ErrorMessageReporter errors={errors} name="name" />

                      {/* description */}
                      <div
                        className={`flex pr-4 bg-gray-50 rounded border ${
                          errors.description
                            ? 'border-red-500'
                            : 'border-gray-200 mb-4'
                        }`}
                      >
                        <input
                          {...register('description', {})}
                          className="w-full pl-4 py-4 text-xs placeholder-gray-500 font-semibold leading-none bg-gray-50 outline-none"
                          type="text"
                          placeholder={
                            'Inserisci qui la descrizione della tua organizzazione'
                          }
                          defaultValue={organization?.description}
                        />
                        <DescriptionIcon className="h-6 w-6 ml-4 my-auto text-gray-300" />
                      </div>
                      <ErrorMessageReporter
                        errors={errors}
                        name="description"
                      />

                      <ProgressButton type="button" state={progressButtonState}>
                        {organization ? 'Modifica' : 'Crea'}
                      </ProgressButton>

                      {error && (
                        <p className="text-xs mt-2 mb-4 text-center text-red-500 font-semibold">
                          {error}
                        </p>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}
