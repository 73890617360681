import Exception from 'api/exceptions/Exception'
import { AxiosError } from 'axios'
import { IsProduction } from 'EnvironmentVariables'

export default class ErrorUtility {
  static GetErrorMessage(error: any) {
    if (error instanceof Exception) {
      return error.message
    } else if (error instanceof AxiosError) {
      if (error.response) {
        // The request was made and the server responded with a status code
        switch (error.response.status) {
          // Forbidden
          case 401:
            return 'Non sei autorizzato'
          case 404:
            // Not found
            return 'Si è verificato un errore inaspettato'
          case 500:
            // Internal Server Error
            return 'Si è verificato un errore inaspettato'
        }
      } else if (error.request) {
        return 'La connessione è troppo leta, impossibile contattare il server'
      } else {
        return 'Errore di rete, assicurarsi di essere connessi ad internet'
      }
    } else if (error instanceof Error) {
      return error.message
    }

    return 'Si è verificato un errore inaspettato'
  }

  static GetErrorCode(error: any) {
    if (error instanceof AxiosError) {
      if (error.response) {
        // The request was made and the server responded with a status code
        return `${error.response.status}`
      } else if (error.request) {
        return 'Timeout'
      } else {
        return 'Rete'
      }
    }

    return '500'
  }

  static LogErrorIfNotInProduction(error: any) {
    if (!IsProduction) {
      console.log(this.GetErrorMessage(error))
    }
  }
}
