import INotification from 'interfaces/account/INotification'
import { ApplicationAPI } from '../ApiConstants'
import RequestService from './RequestService'
import IUser from 'interfaces/user/IUser'

export default class UserService {
  /**
   * Get all users from server
   * @param email, this param is used to filter users by email
   * @returns a collection of users
   */
  static async getUsers<R = IUser[]>(email?: string): Promise<R> {
    return await RequestService.Get(
      `${ApplicationAPI.Users.Base}${email ? `?email=${email}` : ''}`
    )
  }

  /**
   * Get all notifications for the specified user
   * @param email, this param is used to filter users by email
   * @returns a collection of users
   */
  static async getUserNotifications<R = INotification[]>(): Promise<R> {
    return await RequestService.Get<R>(ApplicationAPI.Users.Notifications)
  }
}
