import MediaService from 'api/services/MediaService'
import IMediaElement from 'interfaces/medias/IMediaElement'
import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useMemo,
  useReducer
} from 'react'
import SelectionMode from './SelectionMode'

enum Action {
  SetCurrentMedia = 'SET_CURRENT_MEDIA',
  AddSelectedMedia = 'ADD_SELECTED_MEDIA',
  RemoveSelectedMedia = 'REMOVE_SELECTED_MEDIA',
  SetSelectionMode = 'SET_SELECTION_MODE',
  Reset = 'RESET',
  SetMedias = 'SET_MEDIAS'
}

interface IMediaContextProps {
  children?: ReactElement
  selectionMode?: SelectionMode
  selectedMedias?: IMediaElement[]
}

interface IMediaContext {
  medias: IMediaElement[]
  /** The media currently displayed or selected as active */
  currentMedia: IMediaElement | undefined | null
  /** Selected media, this property is used when selecting the media to be included in the playlist or when viewing content to find out which media is currently selected. */
  selectedMedias: IMediaElement[]
  /** Get the selection mode, if the mode is single, it will be possible to select only one media, otherwise it will be possible to select multiple media. */
  selectionMode: SelectionMode | undefined
  /** Set the selection mode, if the mode is single, it will be possible to select only one media, otherwise it will be possible to select multiple media. */
  setSelectionMode: (value: SelectionMode) => any
  setCurrentMedia: (value: IMediaElement) => any
  selectMedia: (value: IMediaElement) => any
  unselectMedia: (value: IMediaElement) => any
  isSelected: (value: IMediaElement) => boolean
  fetchMedias: () => Promise<IMediaElement[]>
  recentMedias: IMediaElement[]
}

interface IMediaContextState {
  medias: IMediaElement[]
  currentMedia: IMediaElement | undefined | null
  selectedMedias: IMediaElement[]
  selectionMode: SelectionMode | undefined
  recentMedias: IMediaElement[]
}

interface IMediaContextAction {
  type: Action
  currentMedia?: IMediaElement
  selectionMode?: SelectionMode
  mediaToAdd?: IMediaElement
  mediaToRemove?: IMediaElement
  medias?: IMediaElement[]
}

const Context = createContext<IMediaContext | undefined>(undefined)

function init(initialArgs: IMediaContextProps): IMediaContextState {
  var selectionMode = SelectionMode.Single
  if (initialArgs.selectionMode) {
    selectionMode = initialArgs.selectionMode
  }

  var selectedMedias = new Array<IMediaElement>()
  if (initialArgs.selectedMedias) {
    selectedMedias = Array.from(initialArgs.selectedMedias)
  }

  const storedRecentMedias = localStorage.getItem('vic-storage-recent-medias')

  let recentMedias: IMediaElement[] | undefined = undefined
  if (storedRecentMedias && storedRecentMedias !== '') {
    try {
      recentMedias = JSON.parse(storedRecentMedias)
    } catch (error) {
      console.log(error)
    }
  }

  return {
    medias: new Array<IMediaElement>(),
    currentMedia: undefined,
    selectedMedias: selectedMedias,
    selectionMode: selectionMode,
    recentMedias: recentMedias ? recentMedias : new Array<IMediaElement>()
  }
}

function updateRecentMedias(
  recentMedias: IMediaElement[],
  newMedias: IMediaElement[]
) {
  if (recentMedias.length > 5) {
    recentMedias.splice(
      recentMedias.length - newMedias.length,
      newMedias.length
    )
  }

  newMedias.forEach((newMedia) => {
    var index = recentMedias.findIndex((value) => {
      return newMedia.id === value.id
    })

    if (index >= 0) {
      // Remove the media
      recentMedias.splice(index, 1)
    }
  })

  recentMedias.push(...newMedias)

  localStorage.setItem(
    'vic-storage-recent-medias',
    JSON.stringify(recentMedias)
  )
}

function reducer(
  state: IMediaContextState,
  action: IMediaContextAction
): IMediaContextState {
  switch (action.type) {
    case Action.SetCurrentMedia:
      // if current media is invalid return state to avoid refresh
      if (action.currentMedia == null) return state

      updateRecentMedias(state.recentMedias, [action.currentMedia])

      return {
        ...state,
        currentMedia: action.currentMedia
      }
    case Action.AddSelectedMedia:
      // if mediaToAdd is invalid return state to avoid refresh
      if (!action.mediaToAdd) return state

      if (
        (state.selectionMode as SelectionMode) ===
        (SelectionMode.Multiple as SelectionMode)
      ) {
        // If media doesnt exists then add the media
        if (
          state.selectedMedias.findIndex((value) => {
            return action.mediaToAdd?.id === value.id
          }) < 0
        ) {
          state.selectedMedias = Array.from(state.selectedMedias)
          state.selectedMedias.push(action.mediaToAdd)
          updateRecentMedias(state.recentMedias, [action.mediaToAdd])
        }
      } else {
        state.selectedMedias = []
        state.selectedMedias.push(action.mediaToAdd)
        updateRecentMedias(state.recentMedias, [action.mediaToAdd])
        state.currentMedia = action.mediaToAdd
      }

      return {
        ...state
      }
    case Action.RemoveSelectedMedia:
      // if mediaToRemove is invalid return state to avoid refresh
      if (!action.mediaToRemove) return state

      // If media is included then remove the media
      var index = state.selectedMedias.findIndex((value) => {
        return action.mediaToRemove?.id === value.id
      })

      if (index < 0) {
        // The media is not present in the collection
        return state
      }

      // Remove the media
      state.selectedMedias.splice(index, 1)
      state.selectedMedias = Array.from(state.selectedMedias)

      return {
        ...state
      }
    case Action.SetSelectionMode:
      return {
        ...state,
        selectionMode: action.selectionMode
      }

    case Action.SetMedias:
      if (!action.medias) {
        return state
      }

      return {
        ...state,
        medias: action.medias,
        selectedMedias: [],
        currentMedia: undefined
      }
    default:
      throw new Error()
  }
}

export const MediaContext = (props: IMediaContextProps) => {
  const [state, dispatch] = useReducer(reducer, props, init)

  const context = useMemo(() => {
    return {
      medias: state.medias,
      currentMedia: state.currentMedia,
      selectedMedias: state.selectedMedias,
      selectionMode: state.selectionMode,
      recentMedias: state.recentMedias,
      setSelectionMode(value: SelectionMode) {
        dispatch({ type: Action.SetSelectionMode, selectionMode: value })
      },
      setCurrentMedia: (value: IMediaElement) => {
        dispatch({ type: Action.SetCurrentMedia, currentMedia: value })
      },
      selectMedia: (value: IMediaElement) => {
        dispatch({ type: Action.AddSelectedMedia, mediaToAdd: value })
      },
      unselectMedia: (value: IMediaElement) => {
        dispatch({ type: Action.RemoveSelectedMedia, mediaToRemove: value })
      },
      isSelected: (value: IMediaElement) => {
        const result =
          state.selectedMedias.findIndex((selectedMedia) => {
            return selectedMedia.id === value.id
          }) >= 0
        return result
      },
      fetchMedias: async () => {
        const medias = await MediaService.getMedias()
        dispatch({ type: Action.SetMedias, medias: medias })
        return medias
      }
    }
  }, [
    state.currentMedia,
    state.selectedMedias,
    state.medias,
    state.selectionMode,
    state.recentMedias
  ])

  return <Context.Provider value={context}>{props.children}</Context.Provider>
}

export const useMedia = () => {
  const MediaContext = useContext(Context)
  return MediaContext
}

interface MediaConsumerProps {
  children: (value: IMediaContext | undefined) => ReactNode
}

export const MediaConsumer = (props: MediaConsumerProps) => {
  return <Context.Consumer>{props.children}</Context.Consumer>
}
