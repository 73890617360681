import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useMemo,
  useReducer
} from 'react'

interface IFilterContextProps {
  children?: ReactElement
  filter?: string
}

interface IFilterContext {
  filter: string
  setFilter: (filter: string) => any
  resetFilter: VoidFunction
}

interface IFilterContextState {
  filter: string
}

enum Action {
  SetFilter = 'SET_FILTER',
  ResetFilter = 'RESET_FILTER'
}

interface IFilterContextAction {
  type: Action
  filter: string
}

const Context = createContext<IFilterContext | undefined>(undefined)

function init(initialArgs: IFilterContextProps): IFilterContextState {
  return {
    filter: initialArgs.filter ? initialArgs.filter : ''
  }
}

function reducer(
  state: IFilterContextState,
  action: IFilterContextAction
): IFilterContextState {
  switch (action.type) {
    case Action.SetFilter:
      return {
        ...state,
        filter: action.filter
      }
    case Action.ResetFilter:
      return {
        ...state,
        filter: ''
      }
    default:
      throw new Error()
  }
}

export const FilterContext = (props: IFilterContextProps) => {
  const [state, dispatch] = useReducer(reducer, props, init)

  const context = useMemo(() => {
    return {
      filter: state.filter,
      setFilter(value: string) {
        dispatch({ type: Action.SetFilter, filter: value })
      },
      resetFilter() {
        dispatch({ type: Action.ResetFilter, filter: '' })
      }
    }
  }, [state.filter])

  return <Context.Provider value={context}>{props.children}</Context.Provider>
}

export const useFilter = () => {
  const FilterContext = useContext(Context)
  return FilterContext
}

interface FilterConsumerProps {
  children: (value: IFilterContext | undefined) => ReactNode
}

export const FilterConsumer = (props: FilterConsumerProps) => {
  return <Context.Consumer>{props.children}</Context.Consumer>
}
