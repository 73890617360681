import Footer from 'components/footers/Footer'
import Header from 'components/headers/Header'
import MobileMenu from 'components/menus/MobileMenu'
import BackToTop from 'components/scoll/BackToTop'
import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

const Layout = () => {
  const [hiddenClass, setHiddenClass] = useState('hidden')

  const handleHidden = () => setHiddenClass('')

  const handleRemove = () => {
    if (hiddenClass === '') {
      setHiddenClass('hidden')
    }
  }

  return (
    <>
      <div className="main font-body text-body min-h-screen">
        <Header handleHidden={handleHidden} inDashboard={false} />
        <MobileMenu hiddenClass={hiddenClass} handleRemove={handleRemove} />
        <Outlet />
        <Footer />
        <BackToTop />
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  )
}

export default Layout
