import { AxiosResponse } from 'axios'
import IProfile from 'interfaces/user/IProfile'
import IUpdateProfileRequest from 'interfaces/user/IUpdateProfileRequest'
import { ApplicationAPI } from '../ApiConstants'
import RequestService from './RequestService'

export default class ProfileService {
  /**
   * Get profile data from server
   * @returns profile data
   */
  static async getProfileData<R = IProfile>(): Promise<R> {
    return await RequestService.Get(`${ApplicationAPI.Profile}`)
  }

  /**
   * Update profile data from server
   * @returns axios response status
   */
  static async updateProfileData<T = any, R = AxiosResponse<T>>(
    data: IUpdateProfileRequest
  ): Promise<R> {
    return await RequestService.Put(`${ApplicationAPI.Profile}`, data)
  }
}
